.new-message-card__container {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  margin: 0 24px;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    margin: 0 16px;
  }

  .new-message-card__photo {
    height: 58px;
    width: 58px;
    border-radius: 58px;
    border: 1px solid #e8e8e8;
    background: white;
    object-fit: cover;
    margin: 0 8px 0 16px;
  }

  .new-message-card__name {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.51px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
