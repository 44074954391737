@import 'src/variables.scss';

.time-slot__container {
  text-align: center;
  flex: 1;
  display: flex;
  margin: 5px;
  font-weight: 700;
  border-radius: 4px;
  white-space: nowrap;
  border: solid 2px $light-green;

  position: relative;
}

.time-slot__clickable {
  cursor: pointer;
}

.time-slot__date {
  padding: 12px 10px;
  background: $light-green;
  border-radius: 4px;
  flex: 2;
}

.time-slot__time {
  padding: 12px 10px;
  background: white;
  border-radius: 4px;
  flex: 1;
}

.time-slot__close-button {
  margin-left: 10px;
  margin-top: 1px;
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  top: 0;
  right: 0;
}
