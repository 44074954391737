.boost-call__container {
  display: flex;
  flex-direction: column;
}

.boost-call__call-wrapper {
  display: flex;
}

.boost-call__custom-call-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
}

.boost-call__custom-call-button {
  width: 200px;
}

.boost-call__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boost-call__loading-spinner {
  height: 200px;
  width: 200px;
}
