.message-center-container-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .message-center-container-fragment {
    display: flex;
    flex-direction: column-reverse;
  }

  .message-center-container-date-wrapper {
    width: 100%;
    margin: 24px 0;
    display: flex;
    justify-content: center;

    .message-center-container-date {
      padding: 12px 15px;
      border-radius: 100px;
      background-color: #e8e8e8;
      box-sizing: border-box;
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.42px;
      color: #000;
    }
  }
}
