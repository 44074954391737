@import 'src/variables.scss';

.upcoming-details-container {
  width: 97%;
  box-sizing: border-box;
}

.upcoming-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upcoming-view-volunteer {
  font-family: 'Gilroy';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.18px;
  color: #2f80ed;
  cursor: pointer;
}

.upcoming-details-title {
  font-family: 'Gilroy';
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  color: #333;
}

.upcoming-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 21px 0 24px;
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;

  .upcoming-proposed-time {
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.42px;
    color: #333333;
    margin: 0 0 6px;
  }

  .upcoming-time-slot {
    padding: 12px 38px 12px 12px;
    border-radius: 8px;
    border: solid 1px #333333;

    .upcoming-time-slot-txt {
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.42px;
      color: #333333;

      span {
        font-weight: 500;
      }
    }
  }

  .upcoming-date-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .upcoming-date-wrapper {
      display: flex;
      gap: 12px;
    }

    .upcoming-date {
      font-family: 'Gilroy';
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.28px;
      color: #333333;
      margin: 0 0 4px;
    }

    .upcoming-day {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.17px;
      color: #333333;
      margin: 0 0 16px;
    }

    .upcoming-time {
      font-family: 'Gilroy';
      font-size: 28px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.28px;
      color: #333333;
      margin: 0;
    }
  }

  .upcoming-box-buttons-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 25px;

    .upcoming-accept-btn {
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding: 0 16px;
      border-radius: 110px;

      cursor: pointer;
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: center;
    }
    .upcoming-accept-btn__loading {
      background: #e0e0e0;
      color: #a6a6a6;
    }

    .upcoming-messages-btn {
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding: 0;
      border-radius: 110px;
      background-color: transparent;
      cursor: pointer;
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: center;
      color: #a19fa2;
    }

    .upcoming-decline-btn {
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding: 0;
      border-radius: 110px;
      background-color: transparent;
      cursor: pointer;
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: center;
      color: #f26d37;
    }
    .upcoming-decline-btn__loading {
      color: #a6a6a6;
    }
  }

  .starts-box {
    padding: 16px;
    border-radius: 8px;
    background-color: #166b7a;

    .starts-small-text {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.17px;
      text-align: center;
      color: #e9bd5f;
      margin: 0;
    }
    .starts-big-text {
      font-family: 'Gilroy';
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: -0.48px;
      text-align: center;
      color: #e9bd5f;
      margin: 4px 0 2px;
    }
  }
}

.upcoming-info-box {
  width: 100%;
  margin: 21px 0 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  box-sizing: border-box;

  .upcoming-info-label {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.51px;
    text-align: left;
    color: #000;
    margin-bottom: 8px;
  }

  .upcoming-info-text {
    white-space: pre-wrap;
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.17px;
    color: #333;
    margin-bottom: 24px;
  }

  .upcoming-vol-info {
    display: flex;
    align-items: center;

    img {
      height: 60px;
      width: 60px;
      border-radius: 30px;
      margin-right: 8px;
      border: 1px solid #333333;
      object-fit: cover;
    }

    .upcoming-vol-info-column {
      display: flex;
      gap: 4px;

      .upcoming-vol-name {
        font-family: 'Gilroy';
        font-size: 17px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: 0.51px;
        color: #333;
      }

      .upcoming-vol-role {
        font-family: 'Gilroy';
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0.17px;
        color: #333333;
      }
    }
  }

  .upcoming-remove-margin {
    margin: 0;
  }
}
