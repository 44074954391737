.message-center-block__container {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  padding-left: 26px;
  padding-right: 12px;
  box-sizing: border-box;
  background: #ededed;
  border-radius: 8px;

  .message-center-block__wrapper {
    display: flex;
    align-items: center;
    gap: 9px;

    .message-center-block__text {
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.28px;
      color: #000;
    }
  }
}
