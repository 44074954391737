.button-with-dropdown__container {
  position: relative;
}

.button-with-dropdown__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button-with-dropdown__button {
  cursor: pointer;
  min-width: 44px;
  min-height: 44px;
  display: inline-block;
  border: none;
  background: unset;
}

.button-with-dropdown__dropdown {
  position: absolute;
  right: 50%;
  top: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: white;
  box-shadow: 0px 5px 15px 0px #00000040;
  border-radius: 3px;

  list-style-type: none;
  padding: 0;
  margin: 0;
}
