@import 'src/variables.scss';

.recomendations-card__container {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #f3f7fa;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .recommendations-card__title-wrapper {
    display: flex;
    align-items: flex-start;

    .recommendations-card__left {
      display: flex;
      align-items: flex-start;
      gap: 6px;
      width: 100%;
    }

    .recommendations-card__image {
      min-width: 29px;
      min-height: 29px;
      background-image: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .recommendations-card__text-info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;

      .recommendations-card__title {
        font-family: Gilroy;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.42px;
        color: #000;
      }

      .recommendations-card__type {
        font-family: Gilroy;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.28px;
        color: #a3a3a3;
        text-transform: capitalize;
      }

      .recommendations-card__user-info {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .recommendation-card__user {
          display: flex;
          align-items: center;

          .recommendations-card__user-image-container {
            min-width: 22px;
            min-height: 22px;
            border-radius: 22px;
            background: #e3e5e8;
            margin: 0 5px;
            overflow: hidden;

            img {
              height: 22px;
              width: 22px;
              object-fit: cover;
            }
          }

          .recommendation-card__text {
            font-family: Gilroy;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 0.28px;
            color: #333;

            strong {
              font-weight: bold;
            }
          }
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .recommendation-card__time {
          font-family: Gilroy;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.28px;
          color: #333;
        }
      }
    }
  }
}
