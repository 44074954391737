.message-preview-container {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;

  &:hover {
    background: #ededed;
  }

  .message-preview-photo-wrapper {
    display: flex;
    align-items: center;
    gap: 11px;

    .message-preview-photo {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border: 1px solid #e8e8e8;
      background: white;
      object-fit: cover;
    }

    .message-preview-info-wrapper {
      display: flex;
      flex-direction: column;
      width: 170px;
      height: 58px;

      .message-preview-name {
        margin-bottom: 4px;
        font-family: 'Gilroy';
        font-size: 17px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.51px;
        color: #000;
        width: 100%;
      }

      .message-preview-name-text {
        width: 100%;
        max-width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .message-preview-preview {
        margin-bottom: 4px;
        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.51px;
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .message-preview-time {
        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.28px;
        color: #777;
      }
    }
  }
}

.message-preview-selected {
  background: #ededed;
}

.message-preview-whitelabel {
  &:hover {
    background: #ededed;
  }
}

.message-preview-whitelabel-selected {
  background: #ededed;
}
