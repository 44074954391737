@import 'src/variables.scss';

.thank-you-registration {
  box-sizing: border-box;
  padding: 0 0 38px;
  font-family: 'Gilroy';
  padding: 0 80px 38px;

  @media (max-width: 767px) {
    padding: 0 20px 38px;
  }

  h1 {
    font-size: 36px;
    margin: 24px 0 57px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin: 24px 0 23px;
    }
  }

  h3 {
    font-size: 24px;
    margin: 25px 0 15px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
  }

  h6 {
    font-size: 17px;
    margin: 0 0 16px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 12px;
    }
  }

  a {
    color: $text-grey;
  }
}
