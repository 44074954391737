@import 'src/variables.scss';

.container {
  height: 100%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 201;
  text-align: center;
}

.titleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid $background-grey;
}

.icon {
  margin: 15px 5px;
}

.title {
  margin: 15px 0 15px 15px;
}
.sign-up-modal-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.sign-up-modal-description {
  font-size: 16px;
  font-family: 'Gilroy';
  font-weight: 500;
}

.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.bodyText {
  color: $text-grey;
  &:first-child {
    padding-top: 20px;
  }
}

.link {
  font-size: 16px;
  color: $highlight-blue;
  font-weight: 500;
  text-decoration: none;
}

.resendText {
  padding-top: 20px;
  color: $text-grey;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.errorText {
  background-color: $error !important;
}

.notificationText {
  color: white;
  background-color: $success;
  padding: 5px 10px;
  align-self: center;
  border-radius: 3px;
  margin: 10px 0 5px;
  font-weight: 600;
}
