@import 'src/variables.scss';

.dashboard-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 900px) {
    height: auto;
    width: 90vw;
    max-width: 400px;
  }
}

.requests-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 900px) {
    height: auto;
    width: 90vw;
    max-width: 400px;
  }
}

.dashboard-shadow-box {
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
}

.rectangle-icon-container {
  margin-top: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.sent-reschedule-rectangle-icon {
  display: flex;
  background-color: #f1a224;
  justify-content: flex-end;
  align-items: flex-end;
  width: 110px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.sent-reschedule-rectangle-icon:after {
  width: 40px;
  margin: -4px;
  height: 38px;
  border-radius: 80%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.rectangle-icon-text {
  padding-top: 10px;
  width: 90px;
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #fff;
  height: 31px;
  text-align: center;
  justify-content: center;
}

.dashboard-card__container {
  display: grid;
  grid-template-columns: 80px auto;
  padding-left: 20px;
  width: 100%;
  @media screen and (max-width: 900px) {
    grid-template-columns: 50px 265px;
    column-gap: 10px;
    margin-top: 20px;
  }
}

.dashboard-card__container_followup {
  display: grid;
  grid-template-columns: 80px auto;
  padding-left: 20px;
  padding-top: 40px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 50px 265px;
    column-gap: 10px;
    margin-top: 20px;
  }
}

.dashboard-card-header-info {
  margin-left: 10px;
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  @media screen and (max-width: 900px) {
    margin-top: -10px;
  }
}
.dashboard-card-title {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  @media screen and (max-width: 900px) {
    font-size: 14px;
    margin-top: 10px;
  }
}

.dashboard-card-name {
  display: inline-flex;
  flex-grow: 0;
  font-family: Gilroy;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  color: #000;
  text-transform: capitalize;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    display: inline-flex;
    flex-direction: column;
  }
}

.dashboard-paper-time-hour {
  align-items: flex-end;
  display: flex;
  column-gap: 4px;
}
.dashboard-paper-time-day {
  align-items: flex-end;
  display: flex;
  column-gap: 4px;
}

.dashboard-button-container {
  width: fit-content;
  text-align: center;
  @media screen and (max-width: 900px) {
    width: 200px;
  }
}

.dashboard-link-button-container {
  margin-left: 5px;
  width: fit-content;
  @media screen and (max-width: 900px) {
    display: flex;
    width: 200px;
    align-content: center;
    justify-content: center;
  }
}
