.no-messages-wrapper {
  width: 1000px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    width: 100%;
  }

  .no-messages-image {
    height: 331px;
    width: 493px;
    object-fit: contain;

    @media screen and (max-width: 950px) {
      width: 100%;
    }
  }

  .no-messages-title {
    font-family: 'Gilroy';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.24px;
    text-align: center;
    color: #000;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  .no-messages-subtitle {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.34px;
    text-align: center;
    color: #000;
    width: 308px;
  }
}
