@import 'src/variables.scss';

.youtube-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 25;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-body {
  position: relative;
  overflow: hidden;
  width: 850px;
  aspect-ratio: 16/9;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
