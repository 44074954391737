.sign-in-form-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  align-items: center;
}
.submit-button {
  width: '300px';
}
.signin-page-container {
  margin-top: 20px;
  height: auto;
}

.signup-accept-box {
  width: 100%;
}

.partner-checkbox-help-text {
  display: inline;
}

.login-signup-page-container {
  width: 95vw;
  max-width: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
  justify-content: center;
  @media screen and (max-width: 1500px) {
    padding-left: 0;
    padding-right: 0;
  }
}
