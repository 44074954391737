@import 'src/variables.scss';
@import 'src/styling/palette.scss';
.mentor-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .mentor-card__delete {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    border: 1.5px solid #414141;
    position: absolute;
    right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 10px;
      font-weight: 800;
      color: #414141;
    }
  }

  .mentor-card__image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mentor-card__image {
      width: 42px;
      height: 42px;
      border-radius: 42px;
      overflow: hidden;
      margin-bottom: 8px;
      background: #e3e5e8;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .mentor-card__name {
      font-family: Gilroy;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.42px;
      color: #000;
      margin-bottom: 8px;
      width: 100%;
    }
  }

  .mentor-card__interests {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    text-align: center;
    color: #000;
    margin-bottom: 16px;
  }

  .mentor-card__view {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.42px;
    color: $primary;
    cursor: pointer;
  }
}
