.new-message__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  background: rgba(0, 0, 0, 0.37);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 950px) {
    padding: 20px;
  }

  .new-message__body {
    border-radius: 8px;
    background-color: #ffffff;
    width: 405px;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    .new-message__header {
      width: 100%;
      padding: 32px 24px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 950px) {
        padding: 16px 16px 8px;
      }

      p {
        font-family: 'Gilroy';
        font-size: 28px;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.28px;
        color: #333333;
        margin: 0;
        padding: 0;
      }

      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }

    .new-message__bottom {
      width: 100%;
      padding: 25px 24px 23px;
      box-sizing: border-box;

      @media screen and (max-width: 950px) {
        padding: 25px 16px 16px;
      }
    }

    .new-message__scrollable {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .new-message__no-users {
      width: 100%;
      padding: 12px 24px 0;
    }

    .new-message__search {
      padding: 12px 24px;

      @media screen and (max-width: 950px) {
        padding: 12px 16px;
      }

      .search-input {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        border: solid 1px #a3a3a3;
        padding: 0 16px;
        position: relative;
        display: flex;
        align-items: center;

        .search-input-clear-button {
          position: absolute;
          right: 16px;
          cursor: pointer;
          margin-top: 4px;
        }

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;

          font-family: 'Gilroy';
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.34px;

          &::placeholder {
            color: #777;
          }
        }
      }
    }
  }
}
