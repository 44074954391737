@import 'src/variables.scss';
@import 'src/styling/palette.scss';
.button {
  cursor: pointer;
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 10%;
    right: 0;
  }
}

.button__loading-container {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  svg {
    margin: 0 auto;
  }
}

.button__content-hidden {
  visibility: hidden;
}

.disabled {
  pointer-events: none;
}

.button-primary {
  font-family: gilroy;
  border: 1px solid $primary;
  background: $primary;
  color: $contrastTextPrimary;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 2px;
}

.button-primary:hover {
  text-decoration: none;
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.button-tertiary {
  font-family: gilroy;
  border: none;
  background: $light-green;
  color: black;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700 !important;
  transition: 0.3s;
  border-radius: 2px;

  &.disabled {
    color: #777777;
    background-color: #e8e8e8;
  }
}

.button-tertiary:hover {
  text-decoration: none;
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.button-red {
  font-family: gilroy;
  border: 1px solid $primary-blue;
  background: #ff5f5f;
  color: white;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 2px;
}

.button-dark {
  font-family: gilroy;
  border: 1px solid $primary-blue;
  background: $text-grey;
  color: white;
  padding: 6px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 2px;
}

.button-secondary {
  font-family: gilroy;
  border: 1px solid $secondary;
  box-sizing: border-box;
  padding: 6px;
  background: $secondary;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 2px;
}

.button-secondary:hover {
  background-color: $primary;
  border: 1px solid $primary;
  color: $contrastTextPrimary !important;
  cursor: pointer;

  &.disabled {
    background: unset;
    cursor: default;
  }
}

.button-red:hover {
  background: red;
  cursor: pointer;
  &.disabled {
    background: #ff5f5f;
    cursor: default;
  }
}

.button-link-inverse {
  background: transparent;
  color: white;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  text-decoration: underline;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-family: gilroy;
  font-weight: 400;
  transition: 0.3s;
  border-radius: 2px;
}

.button-link-inverse:hover {
  box-shadow: 0 0 0 2px #fff;
  text-decoration: none;
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.button-link {
  background: transparent;
  color: #000000;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  text-decoration: underline;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-family: gilroy;
  font-weight: 400;
  transition: 0.3s;
  border-radius: 2px;
}

.button-link:hover {
  box-shadow: 0 0 0 2px #000000;
  text-decoration: none;
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.button-white {
  background: white;
  color: $primary-blue;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  font-family: gilroy;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 2px;
}

.button-white:hover {
  text-decoration: none;
  &.disabled {
    box-shadow: none;
    cursor: default;
  }
}

.button-ghost {
  font-family: gilroy;
  border: none;
  background-color: transparent;
  color: black;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  cursor: pointer;
  min-width: 44px;
  min-height: 44px;
}

.button-ghost:hover {
  text-decoration: none;
  &.disabled {
    cursor: default;
  }
}
