@import 'src/variables.scss';

.input-with-label {
  font-family: 'Gilroy';
  display: flex;
  flex-direction: column;

  label {
    padding: 12px 0 2px;
    font-size: 16px;
    color: $shade-grey;
  }

  input {
    height: 48px;
    border: 1px solid $shade-grey;
    font-size: 16px;
    padding-left: 22px;
  }
}

.input-with-label__mandatory-asterisk {
  color: $primary-dark;
}
