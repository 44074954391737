@import 'src/variables.scss';

.booking-status-bar {
  position: sticky;
  top: 68px;
  z-index: 3;
  background: white;
  padding: 17px 4%;

  @media screen and (max-width: 950px) {
    top: 48px;
    padding: 17px 4% 0;
  }

  img {
    margin-top: 20px;
  }

  .titles-text {
    h1 {
      font-size: 36px;
      margin: 0px;

      @media screen and (max-width: 767px) {
        font-size: 17px;
      }
    }

    h2 {
      font-size: 17px;
      color: $shade-grey;
      margin-top: 4px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        margin-top: 8px;
      }
    }
  }

  .status-tracker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    min-width: 460px;

    .status {
      width: 25%;
      text-align: center;
    }

    .line {
      border: solid 1px;
      margin: 0% 10%;
      width: 75%;
      top: 13px;
      position: absolute;
      height: 5px;

      &-2 {
        background: linear-gradient(90deg, $light-green 50%, white 0%);
      }

      &-3 {
        background: linear-gradient(90deg, $light-green 100%, white 0%);
      }
    }

    .selected {
      background: $primary-lighter !important;
    }

    .index {
      border: solid 1px;
      border-radius: 35px;
      width: 33px;
      height: 33px;
      position: relative;
      margin-left: 35%;
      margin-bottom: 15px;
      background: white;
      padding-top: 8px;
    }
  }

  .button-container {
    button {
      padding: 12px 91.5px;
      pointer-events: auto;
      border-radius: 50px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .button-rebook {
    button {
      background: #a19fa2;
      color: #ffffff;
      border-color: #a19fa2;
    }
  }
}

.shadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.booking-status-bar__subtitle {
  margin-bottom: 0;
}
