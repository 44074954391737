.recommendation-title-component {
  display: flex;
  justify-content: space-between;
  margin: 0 0 22px 0;
  height: 31px;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  max-width: 600px;
  @media screen and (max-width: 950px) {
    font-size: 24px;
    max-width: unset;
    padding: 0 20px;
  }
}

.recommendation-scroll-container {
  width: calc(100vw - 400px);
  overflow: auto;
  display: flex;
  gap: 16px;
  padding: 20px 10px;
}
.recommendation-scroll-container::-webkit-scrollbar {
  display: none;
}

.slick-slide > div {
  margin-right: 10px;
  margin-top: 10px;
}

.slick-track {
  float: left !important;
}
