@import 'src/variables.scss';

.partner-integrated {
  margin-top: 50px;

  .input-container {
    display: flex;

    .input-with-label {
      width: 100%;
    }

    .text-area-with-label {
      width: 100%;
    }

    span {
      margin-top: 54px;
      margin-right: -21px;
    }
  }

  .names {
    display: flex;
    justify-content: space-between;

    .input-with-label {
      width: 49%;
    }
  }

  .radio-wrapper {
    border: 0 none;
    padding: 0px;
    span {
      margin-right: 5px;
      margin-left: 4px;
    }
  }

  label {
    font-size: 14px;
    color: #777777;
    line-height: 25px;
    padding: 12px 0 0 0;
    margin-top: 15px;
  }

  .question {
    background: $background-grey;
    border-radius: 18px;
    width: 14px;
    height: 14px;
    display: inline-block;
    position: relative;
    margin-left: 6px;
    margin-bottom: -2px;
    cursor: pointer;

    &:hover {
      img {
        filter: brightness(0%);
      }
    }

    img {
      position: absolute;
      top: 3px;
      left: 5px;
    }
  }
  .par .text-area-with-label {
    margin-bottom: 0;
  }

  .org-type {
    margin-top: 43px;
    span {
      margin: 5px;
      display: inline-block;
    }
  }

  .select-country,
  .select-ethnicity,
  .select-timezone,
  .select-sector {
    margin-top: 25px;
  }

  .edit-gender {
    margin-top: 25px;

    div {
      margin-top: 12px;
    }
  }

  .profile-picture-wrapper {
    display: flex;
    margin: 0 0;
  }

  .fileUploader {
    width: 294px;
    padding-top: 41px;
    .fileContainer {
      padding: 0;
      box-shadow: none;

      .chooseFileButton {
        padding: 11px 16px;
        background: white !important;
        color: #777777;
        font-family: 'Gilroy';
        border-radius: 2px;
        border: 1px solid #777777;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }

  .explainer-text {
    margin: 25px 0;
  }

  .profile-picture {
    height: 192px;
    width: 192px;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $shade-grey;
  }

  .profile-picture-wrapper {
    @include media('<phone') {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.partner-integrated__newsletter {
  .newsletter-title {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #777777;
  }

  label {
    padding: 0px;
    margin-left: 4px;
  }

  input {
    margin-bottom: 30px;
  }
}

.add-details__mandatory-asterisk {
  color: $primary-dark;
}

.add-details__mandatory-text {
  font-size: 14px;
  color: $shade-grey;
}

.add-details__timezone-container {
  display: flex;
  flex-direction: column;
}

.add-details__timezone-inner-container {
  display: flex;
}

.add-details__timezone-tooltip {
  position: relative;
}

.add-details__button-container {
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  -webkit-position: sticky;
  bottom: 20px;
  pointer-events: none;
  button {
    padding: 12px 136px;
    pointer-events: auto;
  }
}
.add-details__button-container--single-button {
  justify-content: flex-end;
}

.add-details__back-button {
  background: white;
  padding: 12px 50px !important;
}

.add-details__validation-error {
  display: inline-block;
  color: red;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

#bio-tooltip ul {
  padding: 0 10px;
  margin: 0;

  li {
    margin-bottom: 10px;
  }
}

.partner-integrated__cookie-checkbox {
  margin-top: 30px;

  input {
    margin-right: 10px;
  }
}

.partner-integrated__partner-terms {
  margin-top: 5px;
  margin-bottom: 5px;
}

.partner-integrated__validation-error-container {
  display: flex;
  flex-direction: column;
}

.partner-integrated__validation-error {
  display: inline-block;
  color: red;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}
