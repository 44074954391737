.dashboard-title-component {
  display: flex;
  justify-content: space-between;
  margin: 0 0 22px 0;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  max-width: 600px;
  gap: 20px;
  @media screen and (max-width: 950px) {
    font-size: 24px;
    max-width: unset;
    padding: 0 20px;
  }

  .dashboard-title-text {
    width: 100%;
    word-break: break-word;

    & > div {
      margin-top: 6px;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.empty-page-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50vh;
  justify-items: center;
}

.masterclass-empty-page-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: 100%;
  height: 70vh;
  justify-items: center;
}
.not-found-image {
  width: 272px;
  height: 225px;
  justify-self: center;
}

.masterclass-not-found-text {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  width: 80%;
  color: #000;
  @media screen and (max-width: 900px) {
    font-size: 20px;
    margin-left: 10px;
    width: 90%;
  }
}
.not-found-text {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  color: #000;
  @media screen and (max-width: 900px) {
    font-size: 20px;
    margin-left: 10px;
  }
}
.not-found-text-container {
  font-family: Gilroy;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  justify-content: center;
  color: #000;
}

.loading-button-container {
  width: 100%;
  @media screen and (max-width: 900px) {
    max-width: 400px;
  }
  @media screen and (max-width: 500px) {
    max-width: 90vw;
  }
}

.tab-panel-requests-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  @media screen and (max-width: 900px) {
    row-gap: 0;
  }
}
