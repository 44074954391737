@import 'src/variables.scss';
@import 'src/styling/palette.scss';

.delete-mentor-pop-up {
  .select-button {
    height: 29px;
    top: 12px;
    background: white;
  }

  .true {
    background: $primary;
  }

  .remove {
    div {
      margin-bottom: 8px;
      text-align: left;
    }
    text-align: center;

    position: absolute;
    left: 95px;
    bottom: 424px;
    background: white;
    border: solid 1px;
    padding: 10px 10px;
    width: 200px;
    font-weight: 700;
    z-index: 1033;
    font-size: 15px;

    Button {
      margin: 5px 0;
      width: 176px;
      height: 44px;
      border: none;
    }
  }
}
