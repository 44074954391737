@import 'src/variables.scss';

.card-with-header__card {
  border-radius: 8px;
}

.card-with-header__header {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  color: $light-grey;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: white;
  color: $text-grey;
  border-bottom: 1px solid $tag-grey;
}

.card-with-header__header-number-container {
  padding: 10px 40px;
  margin-right: 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: linear-gradient(to left, $primary-darker, $primary-darkish);
  color: white;
}

.card-with-header__header-text {
  margin: 5px 10px;
  align-items: center;
  padding: 5px 0;
}

.card-with-header__darkest {
  background: linear-gradient(
    to left,
    $primary-darkest,
    $primary-darker,
    $primary-darkest
  );
  color: white;
}

.card-with-header__darker {
  background: $background-blue;
  color: white;
}

.card-with-header__darkish {
  background: linear-gradient(
    to left,
    $primary-darkish,
    $primary-dark,
    $primary-darkish
  );
  color: white;
}

.card-with-header__dark {
  background: linear-gradient(
    to left,
    $primary-dark,
    $primary-light,
    $primary-dark
  );
  color: white;
}

.card-with-header__light {
  background: linear-gradient(
    to left,
    $primary-light,
    $primary-lightish,
    $primary-light
  );
  color: $text-grey;
}

.card-with-header__lightish {
  background: linear-gradient(
    to left,
    $primary-lightish,
    $primary-lighter,
    $primary-lightish
  );
  color: $text-grey;
}

.card-with-header__lighter {
  background: linear-gradient(
    to left,
    $primary-lighter,
    $primary-lightest,
    $primary-lighter
  );
  color: $text-grey;
}
