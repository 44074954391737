@import 'src/variables.scss';
@import 'src/styling/palette.scss';

.select-button {
  width: 100%;
  height: 38px;
  border: none;
  box-sizing: border-box;
  border-radius: 50px;
  font-weight: 700;
  font-family: 'Gilroy';
  text-transform: capitalize;
  border: solid 1px;
  cursor: pointer;
  z-index: 1;
}
