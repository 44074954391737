.group-event-call__container {
  display: flex;
  flex-direction: column;
}

.group-event-call__custom-call-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
}

.group-event-call__custom-call-button {
  width: 200px;
}
