.error-page__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;

  padding: 0 50px;
}

.error-page__inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.error-page__button-container {
  width: 100%;
  margin-top: 30px;
}

.error-page__button {
  width: 300px;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}
