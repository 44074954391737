@import 'src/variables.scss';

.profile-photo-container {
  width: 58px;
  height: 58px;
  overflow: hidden;
  border-radius: 50%;
  @media screen and (max-width: 900px) {
    width: 48px;
    height: 48px;
  }
}

.profile-photo-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.request-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  font-family: Gilroy;
  padding-left: 4px;
}
.request-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}

.request-paper-button-wrapper-followup {
  display: grid;
  margin-top: 10px;
  margin-bottom: 20px;
  grid-template-columns: min-content min-content min-content;
  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
    margin-left: -50px;
  }
}
.request-paper-time-slots {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 15px;
}
.request-paper-time-slots-proposed {
  display: flex;
  align-items: center;
  gap: 14px;
}
.request-paper-slots {
  padding-top: 15px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.requested-paper-slots {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #414141;
}
.request-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.requested-dashboard-button-container {
  width: 120px;
  @media screen and (max-width: 950px) {
    display: flex;
    justify-content: center;
    width: calc(min(75vw, 350px));
  }
}

.rectangle-icon {
  display: flex;
  background-image: linear-gradient(92deg, #ffb879 10%, #f2c94c 100%);
  justify-content: flex-end;
  align-items: flex-end;
  width: 110px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.rectangle-icon:after {
  width: 40px;
  margin: -4px;
  height: 38px;
  border-radius: 80%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.proposed-time-slot {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 20px;
}
