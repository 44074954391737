@import 'src/variables.scss';

.container {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
