@import 'src/variables.scss';

.recommended-mentor__container {
  width: 466px;
  padding: 24px;
  height: 240px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  position: relative;

  .recommended-mentor__delete {
    width: 17px;
    height: 17px;
    border-radius: 17px;
    border: 1.5px solid #414141;
    position: absolute;
    top: 12px;
    right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 10px;
      font-weight: 800;
      color: #414141;
    }
  }
}

.recommended-mentor__row {
  display: flex;
  margin-bottom: 10px;

  .profile-photo-container {
    min-width: 121px;
    min-height: 114px;
    max-width: 121px;
    max-height: 114px;
    border-radius: 8px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recommended-mentor__info {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .recommended-mentor__name {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.24px;
      color: #000;
    }

    .recommended-mentor__profession {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.42px;
      color: #0f0f22;
      margin-bottom: 4px;
    }

    .recommended-mentor__company {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.28px;
      color: #0f0f22;
      margin-bottom: 11px;
    }

    .recommended-mentor__tag-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .recommended-mentor__tag {
        padding: 4px 8px;
        border-radius: 52px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.28px;
        text-align: left;
        color: #0f0f22;
      }
    }
  }
}

.recommended-mentor__button {
  margin-top: 12px;
  padding: 0 24px;
  width: 122px;
  height: 38px;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.42px;
  color: #191e39;
}
