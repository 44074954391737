@import 'src/styling/palette.scss';

.statistics-title {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.organisations-helped-photo-card-container {
  min-width: 36px;
  max-width: 36px;
  border-radius: 50%;
  min-height: 36px;
  max-height: 36px;
  flex-grow: 0;
  align-items: center;
  display: grid;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    #fdd4c2 0%,
    rgba(253, 213, 195, 0.22) 100%
  );
}
.organisations-helped-photo-card {
  width: 13px;
  height: 13px;
}

.masterclass-statistics-photo-card-container {
  min-width: 60px;
  max-width: 60px;
  border-radius: 50%;
  min-height: 60px;
  max-height: 60px;
  flex-grow: 0;
  align-items: center;
  display: grid;
  justify-content: center;
  background-image: #fff;
}
.masterclass-statistics-photo-card {
  padding: 15px;
}

.statistics-container {
  display: flex;
  column-gap: 10px;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }
}

.statistics-paper-container {
  display: inline-flex;
  width: 100%;
  margin-top: -10px;
  padding: 0 24px;
  box-sizing: border-box;
}

.statistics-paper-info {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  row-gap: 3px;
  margin-left: 16px;
}

.statistics-tooltip {
  width: 100%;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
}

.statistics-reward {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  @media screen and (max-width: 950px) {
    column-gap: 20px;
  }
}

.statistics-badge {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.28px;
  text-align: left;
  width: 100px;
}

.statistics-paper-box {
  width: 100%;
  height: 120px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.chart-paper-box {
  height: auto;
  flex-grow: 0;
  width: 459px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 100%;
    padding-top: 10px;
    height: 230px;
  }
}

.chart-text-container {
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 950px) {
    width: 240px;
  }
}

.chart-bold-text {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
  text-align: right;
  @media screen and (max-width: 950px) {
    width: 300px;
    font-size: 14px;
  }
}

.chart-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  text-align: right;
  @media screen and (max-width: 950px) {
    width: 310px;
    font-size: 12px;
  }
}

.sessions-photo-card-container {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  border-radius: 50%;
  flex-grow: 0;
  align-items: center;
  display: grid;
  justify-content: center;
  background-image: linear-gradient(to bottom, #c2d9fd 0%, #e8f1ff 100%);
}

.octagon-sessions-photo {
  width: 24px;
  height: 24px;
  flex-grow: 0;
}

.minutes-mentored-photo-card-container {
  min-width: 40px;
  min-height: 40px;
  flex-grow: 0;
  border-radius: 50%;
  flex-grow: 0;
  align-items: center;
  display: grid;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    #abffcd 0%,
    rgba(170, 255, 206, 0.28) 100%
  );
}

.minutes-mentored-photo {
  width: 24px;
  height: 24px;
  flex-grow: 0;
}

.tootltip-statistics-icon {
  display: inline-flex;
  width: 100%;
  align-items: left;
  column-gap: 5px;
}

.tootltip-statistics {
  display: inline-flex;
  width: 90%;
  margin-top: 15px;
  margin-left: 25px;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  text-align: center;
  column-gap: 5px;
  margin-bottom: -100px;
  align-items: center;
  @media screen and (max-width: 950px) {
    width: 300px;
    max-width: 75vw;
    height: 50px;
    margin-top: 0;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }
}

.dashboard-chart-bold-text {
  font-family: Gilroy;
  font-size: 17px;
  margin-top: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.dashboard-tootltip-statistics {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-chart-text {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.dashboard-chart-center {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.dashboard-chart-paper-box {
  width: 100%;
  height: 220px;
  padding: 16px 21px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $secondary;
  max-width: 500px;
  @media screen and (max-width: 950px) {
    margin: 0;
    margin-top: 15px;
    max-width: 90vw;
  }
}
.dashboard-chart-stats-box {
  flex: 1;
  min-height: 180px;
  padding: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $secondary;
  @media screen and (max-width: 950px) {
    margin: 0;
    margin-top: 5px;
    min-height: 100%;
  }
}

.dashboard-impact-statistics-box {
  display: inline-flex;
  flex-direction: column;
}
.dashboard-impact-statistics {
  display: inline-flex;
  flex: 1;
  margin-top: 8px;
  gap: 8px;
}

.chart-text-helper {
  font-family: Gilroy;
  font-size: 14px;
  margin-top: 3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
  @media screen and (max-width: 950px) {
    font-size: 10px;
  }
}
.tootltip-statistics-icon-info {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2px;
  margin-top: -10px;
  @media screen and (max-width: 950px) {
    margin-top: 0;
  }
}

.subjects-text {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.34px;
  text-align: left;
  color: #000;

  span {
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    color: #777;
  }
}

.dashboard-chart-text-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
