.message-component-container {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;

  .message-component-photo-wrapper {
    display: flex;
    gap: 8px;
    align-items: flex-end;

    .message-component-photo {
      min-width: 58px;
      min-height: 58px;
      max-width: 58px;
      max-height: 58px;
      border-radius: 50%;
      border: 1px solid #e8e8e8;
      object-fit: cover;
    }

    .message-component-text {
      padding: 20px 16px;
      box-sizing: border-box;
      background-color: #ededed;
      border-radius: 8px 8px 8px 0;
      display: flex;
      font-family: 'Gilroy';
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.18px;
      text-align: left;
      color: #000;
      white-space: pre-line;
    }

    .right-side {
      background-color: #333333;
      border-radius: 8px 8px 0 8px;
      color: #ffffff;
    }

    .message-component__link-text {
      font-family: 'Gilroy';
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.18px;
      text-align: left;
      color: #000;
      word-break: break-all;
    }

    .message-components__link-text-right {
      color: #ffffff;
    }
  }
}

.align-right {
  justify-content: flex-end;
}
