.container {
  height: 60vh;
  min-height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  height: 200px;
  width: 200px;
}
