.access-paused {
  @media (max-width: 1440px) {
    margin: 38px 4%;
    margin-top: 75px;
  }
  margin: 38px 10%;
  margin-top: 75px;
  font-family: 'Gilroy';
  text-align: left;

  Button {
    margin: 0 25px;
  }

  .access-paused-message {
    text-align: center;

    h2 {
      font-size: 36px;
      font-weight: 200;
    }

    .button-group {
      Button {
        width: 220px;
        padding: 10px 20px;
        margin: 5px 5%;
      }
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      flex-wrap: wrap;
    }

    div {
      margin: 50px;
    }
  }
}

.access-paused__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.access-paused__loading-spinner {
  height: 200px;
  width: 200px;
}
