@import 'src/variables.scss';

.mentor-search-filters {
  margin: 0 30px;

  @media screen and (max-width: 1024px) {
    margin: 0 15px;
  }

  .skill-list {
    width: 100%;
    text-align: left;
    padding-top: 24px;

    h3 {
      font-size: 17px;
      color: $black;
      margin: 0 0 16px;
    }

    .question {
      background: $marker-blue;
      border-radius: 18px;
      min-width: 14px;
      min-height: 14px;
      display: inline-block;
      position: relative;

      img {
        position: absolute;
        top: 3px;
        left: 5px;
      }
    }
    .parent-skill {
      white-space: nowrap;
      margin-bottom: 22px;
      .radio-btn {
        margin-right: 9px;
      }

      .selected {
        font-weight: bold;
      }

      cursor: pointer;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    .parent-selected {
      margin-bottom: 14px !important;
    }
    .container {
      cursor: pointer;
      padding: 0;
      margin-bottom: 19px;

      .child-skill {
        display: flex;
        align-items: center;
        gap: 9px;
        text-align: left;
        white-space: pre-wrap;
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .child-radio-btn {
          margin-right: 9px;
        }

        .selected-label {
          font-weight: bold;
          text-align: left;
        }
      }
    }

    .plus {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }

    .minus {
      width: 12px;
      height: 12px;
      object-fit: contain;
    }
  }
}
