@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Gilroy', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1200px;
  padding: 0 14px;
  margin: 0 auto;
}

.react-datepicker__day {
  &:focus {
    outline: none;
  }
}

// CookieBot CSS customisation
#CybotCookiebotDialog {
  border: None !important;
}

#CybotCookiebotDialog * {
  font-family: 'Gilroy' !important;
}
