@import 'src/variables.scss';

.profile-photo-container {
  width: 58px;
  height: 58px;
  flex-grow: 0;
  overflow: hidden;
  border: 1px solid rgb(114, 111, 111);
  border-radius: 50%;
}

.profile-photo-card {
  width: 58px;
  height: 58px;
  border: 1px solid rgb(114, 111, 111);
  position: relative;
  display: grid;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.upcoming-paper-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: -15px;
}
.upcoming-paper-header {
  display: grid;
  grid-template-columns: 80px auto;
}
.upcoming-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.upcoming-group-event-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin: 0px 0 0 80px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.upcoming-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  padding-left: 4px;
}
.upcoming-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.upcoming-paper-button-wrapper {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: min-content min-content min-content min-content;
  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
  }
}
.upcoming-paper-slots {
  padding-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.upcoming-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.upcoming-rectangle-icon {
  display: flex;
  background-color: #8ac70a;
  justify-content: flex-end;
  align-items: flex-end;
  width: 110px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.upcoming-rectangle-icon:after {
  width: 40px;
  margin: -4px;
  height: 38px;
  border-radius: 80%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}
