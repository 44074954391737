@import 'src/variables.scss';

.add-details {
  margin-top: 50px;

  .disability-field{
    margin-top: 20px;
  }
  .refresh-from-linked-in {
    .button-wrapper {
      .linked-in-button {
        width: 100%;
        height: 46px;
        border: none;
        background: $linked-in-blue;
        font-family: 'Gilroy';
        font-size: 18px;
        color: white;
        position: relative;
        border-radius: 5px;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }
    }
  }

  .input-container {
    display: flex;

    .input-with-label {
      width: 100%;
    }

    .text-area-with-label {
      width: 100%;
    }

    span {
      margin-top: 54px;
      margin-right: -21px;
    }
  }

  .names {
    display: flex;
    justify-content: space-between;
column-gap: 20px;
    .input-with-label {
      width: 49%;
    }
  }

  .linked-in-description-text {
    margin: 15px 0;
    text-align: center;
  }

  .radio-wrapper {
    border: 0 none;
    padding: 0px;
    span {
      margin-right: 5px;
      margin-left: 4px;
    }
  }

  label {
    font-size: 14px;
    color: #1d1d1d;
    line-height: 25px;
  }

  .question {
    background: $background-grey;
    border-radius: 18px;
    width: 14px;
    height: 14px;
    display: inline-block;
    position: relative;
    margin-left: 6px;
    margin-bottom: -2px;
    cursor: pointer;

    &:hover {
      img {
        filter: brightness(0%);
      }
    }

    img {
      position: absolute;
      top: 3px;
      left: 5px;
    }
  }
  .par .text-area-with-label {
    margin-bottom: 0;
  }

  .org-type {
   display: inline-flex;
   flex-direction: column;
   row-gap: 20px;
  }



  .profile-picture-wrapper {
    display: flex;
    margin: 0 0;
  }

  .fileUploader {
    width: 294px;
    padding-top: 41px;
    .fileContainer {
      padding: 0;
      box-shadow: none;

      .chooseFileButton {
        padding: 11px 16px;
        background: white !important;
        color: #777777;
        font-family: 'Gilroy';
        border-radius: 2px;
        border: 1px solid #777777;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }

  .explainer-text {
    margin: 25px 0;
  }

  .profile-picture {
    height: 192px;
    width: 192px;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $shade-grey;
  }

  .profile-picture-wrapper {
    @include media('<phone') {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.newsletter {
  .newsletter-title {
    margin: 0px;
    margin-bottom: 10px;
    color: #777777;
  }

  label {
    padding: 0px;
    margin-left: 4px;
  }

  input {
    margin-bottom: 30px;
  }
}

.add-details__mandatory-asterisk {
  color: $primary-dark;
}

.add-details__mandatory-text {
  font-size: 14px;
  color: $shade-grey;
}

.add-details__timezone-container {
  display: flex;
  flex-direction: column;
}

.add-details__timezone-inner-container {
  display: flex;
}

.add-details__timezone-tooltip {
  position: relative;
}

.add-details__button-container {
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  -webkit-position: sticky;
  bottom: 20px;
  pointer-events: none;
  button {
    padding: 12px 136px;
    pointer-events: auto;
  }
}
.add-details__button-container--single-button {
  justify-content: flex-end;
}

.add-details__back-button {
  background: white;
  padding: 12px 50px !important;
}

.add-details__validation-error {
  display: inline-block;
  color: red;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

#bio-tooltip ul {
  padding: 0 10px;
  margin: 0;

  li {
    margin-bottom: 10px;
  }
}
