@import 'src/variables.scss';

.mentor-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
  gap: 24px;
  position: relative;
}
