@import 'src/variables.scss';
@import 'src/styling/palette.scss';
.confirm-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 16px 0;
  text-align: left;

  .custom-call-provider {
    margin: 50px 50px;

    input {
      display: flex;
      align-self: center;
      width: 60%;
      min-width: 400px;
    }
  }

  .error {
    color: red;
  }

  .custom-button {
    font-family: 'Gilroy';
    font-weight: 700;
    margin: 20px 20px 0 0;
    padding: 10px;
    background: #e8e8e8;
    border-radius: 2px;
    border: none;
  }

  .choose-times {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .c-container {
      display: flex;
      flex-direction: column;
      min-width: 209px;
      align-items: center;
      justify-content: center;

      .date-container {
        display: flex;

        .calendar {
          color: $background-blue;
          padding-right: 2px;
        }
      }
    }

    button {
      font-family: 'Gilroy';
      font-weight: 700;
      padding: 10px 10px;
      background: $tag-grey;
      border-radius: 2px;
      border: none;
      cursor: pointer;
    }
  }

  .button-disabled {
    background-color: #e8e8e8;
    color: #777777;
    border: 0;
    cursor: not-allowed;
  }

  .time-slots {
    .time-slot {
      margin-bottom: 15px;
    }
  }
}

.confirm-call__container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  @include media('<phone') {
    max-width: 90%;
  }
}

.confirm-call__thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 16px 0;

  @include media('>phone') {
    margin: 30px 20px 0;
  }

  .button-primary {
    background-color: #88fbcb;
    border: 1px solid #88fbcb;
    color: #0f0f22;

    &:hover {
      background-color: #dffff2;
      border: 1px solid #000;
    }
  }

  .remove-button {
    background-color: #ee5c5c;
    border: 1px solid #ee5c5c;
    color: #ffffff;

    &:hover {
      background-color: #fb8888;
      border: 1px solid #000;
    }
  }

  .confirm-call__button-wrapper {
    margin-top: 44px;

    button {
      &:first-child {
        max-width: 286px;
        margin-right: 27px;

        @include media('<phone') {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        max-width: 203px;
      }
    }
  }

  .addeventatc.button-primary {
    font-family: gilroy;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 10px;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 0;
    background-color: #88fbcb;
    border: 1px solid #88fbcb;
    color: #0f0f22;

    &:hover {
      border: 1px solid #000;
    }

    .addeventatc_icon {
      display: none;
    }
  }
}

.confirm-call__thank-you {
  text-align: left;
  max-width: 600px;

  @include media('<phone') {
    max-width: 90%;
  }

  h3 {
    font-size: 36px;
    margin: 0 0 20px;
  }

  .options-buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.appointments-expired-container {
  display: flex;
  justify-content: center;
  margin: 0 16px;

  @include media('>phone') {
    margin: 0 20px;
  }
}

.appointments-expired {
  max-width: 600px;
  margin-top: 30px;
  text-align: left;

  h3 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 36px;
    color: #000;
  }

  .confirm-call__button-wrapper {
    margin-top: 44px;

    button {
      &:first-child {
        max-width: 286px;
        margin-right: 27px;

        @include media('<phone') {
          margin-bottom: 20px;
        }
      }

      &:last-child {
        max-width: 203px;
      }
    }
  }
}

.decline-call__thank-you {
  text-align: left;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }

  h3 {
    font-size: 17px;
    font-weight: 300;
  }

  .options-buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.decline-call__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.decline-call__loading-spinner {
  height: 200px;
  width: 200px;
}

.decline-call__db-team-text {
  margin-top: 0;
}
.decline-call__regards-text {
  margin-bottom: 0;
}

.decline-call__button-container {
  display: flex;
  flex-direction: column;
  width: 243px;
}
.decline-call__button {
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
}

.cancel-call__thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 16px 0;

  @include media('>phone') {
    margin: 30px 20px 0;
  }

  .button-primary {
    background-color: #88fbcb;
    border: 1px solid #88fbcb;
    color: #0f0f22;

    &:hover {
      background-color: #dffff2;
      border: 1px solid #000;
    }
  }

  .remove-button {
    background-color: #ee5c5c;
    border: 1px solid #ee5c5c;
    color: #ffffff;

    &:hover {
      background-color: #fb8888;
      border: 1px solid #000;
    }
  }
}

.cancel-call__thank-you {
  text-align: left;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }

  h3 {
    font-size: 17px;
    font-weight: 300;
  }

  .options-buttons {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.decline-call__btn {
  margin-top: 15px;
}

.mentor-modal__alert {
  color: red;
  margin-top: 12px;
  font-weight: bold;
}
