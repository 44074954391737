.message-preview-dropdown-button {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: inline-block;
  border: none;
  background: unset;
}

.message-preview-dropdown {
  width: 113px;
  padding: 16px;
  border-radius: 8px;
}

.messages-preview-block-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  border: none;
  background: unset;

  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.28px;
  color: #000;
}
