@import 'src/variables.scss';

.container {
  position: relative;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;

  &:hover {
    .text_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: flex-start;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.text_container {
  display: none;
  position: absolute;
  bottom: -82px;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  z-index: 30;
  width: 250px;
  height: 75px;
  padding: 0 10px;
  border-radius: 3px;
  background: white;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    display: flex;
    justify-content: center;
    align-content: flex-start;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
  width: auto;
  text-align: center;

  a {
    font-weight: 600;
  }
}

.timezone_container {
  text-align: center;
  width: 100%;
  justify-content: center;
}

.timezone_text {
  font-weight: 600;
  font-size: 16px;
  width: auto;
  text-align: center;
}

.pointer {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 75px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  padding: 0 15px;
  z-index: 40;
  &:hover .business-health-info__container {
    display: block;
  }
}
