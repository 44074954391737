@import 'src/variables.scss';

.invite-card-interest {
  margin: 8px 25px 20px 0px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.invitation-rectangle-icon {
  display: flex;
  background-color: #a378ff;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100px;
  height: 30px;
  position: relative;
  overflow: hidden;
}
.invitation-rectangle-icon:after {
  width: 40px;
  margin: -4px;
  height: 38px;
  border-radius: 80%;
  background: rgb(255, 255, 255);
  display: block;
  position: absolute;
  content: '';
  left: -20px;
}

.mastercard-photo-card {
  background-image: #f6f6f6;
  width: 58px;
  height: 58px;
  flex-grow: 0;
  padding: 5px;
  @media screen and (max-width: 900px) {
    width: 48px;
    height: 48px;
  }
}

.invitation-paper-button-wrapper {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: min-content min-content;
  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
  }
}

.decline-invite-card-name {
  margin: 0px 7px 12px 68px;
  font-family: Gilroy;
  font-weight: bold;
  color: #333;
}
.decline-invite-container {
  display: inline-flex;
  margin-left: 40px;
  flex-direction: column;
  row-gap: 20px;
}

.cancel-invitation-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
