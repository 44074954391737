@import 'src/variables.scss';

.recommendation-card__container {
  display: grid;
  grid-template-columns: 80px auto;
  padding-left: 20px;
  padding-top: 20px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 50px 265px;
    column-gap: 10px;
  }
}

.recommendation-card__volunteer {
  display: flex;
  align-items: center;

  div {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #333;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.42px;
    text-align: left;
    color: #333;
  }

  .profile-photo-container {
    width: 22px;
    height: 22px;
    overflow: hidden;
    border-radius: 22px;
    margin: 0 5px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.recommendation-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    padding: 0 20px;
    gap: 12px;
  }
}

.recommendation-header-margin {
  @media screen and (max-width: 950px) {
    margin-top: 24px;
  }
}

.recommendation-text-wrapper {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    flex: 1;
  }
}

.recommendation-buttons-wrapper {
  display: flex;
  gap: 8px;
}

.recommendation-section-title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  color: #333;
  margin-bottom: 4px;
}

.recommendation-section-subtitle {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.17px;
  color: #000;
}

.recommendation-slider-relative {
  width: 100%;
  height: 300px;
  position: relative;
}

.recommendation-slider-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
