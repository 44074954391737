.dashboard-bookings-container {
  width: 718px;
  display: inline-flex;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
}

.tab-panel-bookings-container {
  display: inline-flex;
  flex-direction: column;
  max-width: 718px;
  @media screen and (max-width: 950px) {
    width: 100%;
    display: inline-flex;
    row-gap: 20px;
    flex-direction: column;
  }
}
