@import 'src/variables.scss';

.profile-photo-container-completed {
  width: 58px;
  height: 58px;
  flex-grow: 0;
  overflow: hidden;
  border: 1px solid rgb(114, 111, 111);
  border-radius: 50%;
  cursor: pointer;
}

.profile-photo-card {
  width: 60px;
  height: 60px;
  object-fit: cover;
  @media screen and (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
}

.completed-paper-header-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
}
.completed-paper-header {
  display: grid;
  grid-template-columns: 80px auto;
}
.completed-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 900px) {
    font-size: 14px;
    margin-top: 0;
  }
}

.completed-group-event-paper-time {
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  margin: 0px 0 0 80px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}

.completed-paper-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  padding-left: 4px;
}
.completed-paper-organisation {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.completed-paper-button-wrapper {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: min-content min-content min-content min-content;
  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
  }
}
.completed-paper-slots {
  padding-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.completed-paper-topic {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
}
.completed-card__container {
  display: grid;
  grid-template-columns: 80px auto;
  padding-top: 20px;
  padding-left: 20px;
  @media screen and (max-width: 900px) {
    display: inline-flex;
    column-gap: 10px;
    margin-top: 30px;
  }
  @media screen and (max-width: 500px) {
    display: inline-flex;
    column-gap: 10px;
    padding: 5px;
  }
}

.completed-paper-box {
  width: 670px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;

  @media screen and (max-width: 900px) {
    width: 90vw;
    max-width: 400px;
    height: 300px;
  }
}

.completed-masterclass-paper-box {
  width: 603px;
  height: 199px;
  flex-grow: 0;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
}
