.block-modal__modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.block-modal__close {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-modal__close-button {
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: inline-block;
  border: none;
  background: unset;
  margin-right: 5px;
}

.block-modal__title-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.block-modal__title {
  margin-left: 15px;
  font-weight: 600;
}

.block-modal__text-1 {
  font-size: 16px;
  margin-top: 30px;
}

.block-modal__text-2 {
  font-size: 16px;
  margin-top: 15px;
}

.block-modal__confirm-button {
  min-width: 200px;
  border-radius: 3px;
  margin-top: 30px;
}
