@import 'src/variables.scss';

.atc-hidden-text {
  position: absolute;
  visibility: hidden;
}

.addToCalendarWrapper {
  margin-top: -8px;

  div:first-child {
    font-family: 'Gilroy';
    color: #333;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    margin-left: 6px;

    &:hover {
      text-decoration: none;
    }
  }
}

.confirmRescheduleAoCalendarWrapper {
  margin-top: -8px;

  div:first-child {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 0 16px;
    border-radius: 110px;
    background-color: #f1f1f1;
    cursor: pointer;
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.42px;
    text-align: center;
    color: black;

    &:hover {
      background-color: #f1f1f1;
      color: black;
    }
  }
}
