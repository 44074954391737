.reschedule-card-info {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 15px 0 10px 0;
  height: 30px;
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  color: #000;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    font-size: 14px;
    height: 50px;
    margin-left: 0;
    align-items: flex-start;
  }
}

.reschedule-card-confirmation-info {
  display: inline-flex;
  flex-direction: row;
  margin: 25px 20px 10px 10px;
  height: 17px;
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  color: #000;
  align-self: center;

  @media screen and (max-width: 950px) {
    margin: 0;
    height: auto;
    font-size: 14px;
    flex-direction: column;
    margin-top: 20px;
    width: 250px;
  }
}

.reschedule-card-text {
  width: 185px;
  height: 20px;
  flex-grow: 0;
  margin: 23px 142px 12px 0;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  color: #333;

  @media screen and (max-width: 950px) {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.reschedule-close-dialog-icon {
  margin-top: 5px;
  width: 95%;
  display: flex;
  justify-content: flex-end;
}

.reschedule-card-date-time {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.reschedule-card-container {
  margin-left: 20px;
  display: inline-flex;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    margin-left: 0px;
    width: 100%;
  }
}

.reschedule-card-first-name {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  padding-left: 4px;
  font-size: 17px;
  font-family: Gilroy;
}

.reschedule-card-time {
  display: inline-flex;
  column-gap: 15px;
  margin-left: 10px;
  align-items: center;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: center;
  color: #333;

  @media screen and (max-width: 950px) {
    font-size: 14px;
    margin-left: 0px;
    margin-top: 10px;
  }
}

.reschedule-card-time-hour {
  align-items: center;
  column-gap: 4px;
  display: inline-flex;
}

.reschedule-card-time-day {
  align-items: center;
  column-gap: 6px;
  display: inline-flex;
}

.reschedule-card-confirmation-message {
  height: 96px;
  margin: 5px 0 10px 0;
  padding: 16px 27px 7px 28px;
  font-family: Gilroy;
  font-size: 17px;
  text-align: center;
  color: #000;

  @media screen and (max-width: 950px) {
    padding: 0;
    height: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.reschedule-card-title {
  width: auto;
  height: 31px;
  flex-grow: 0;
  margin: 6px 73px 1px 1px;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;

  @media screen and (max-width: 950px) {
    font-size: 17px;
    margin: 0;
    width: 100%;
  }
}

.reschedule-box-container {
  width: 680px;
  height: auto;
  padding: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;

  @media screen and (max-width: 950px) {
    width: 300px;
    top: 40%;
    padding: 20px;
  }
}

.reschedule-card-confirmation-title {
  width: auto;
  height: 31px;
  flex-grow: 0;
  margin: 6px 0 1px 1px;
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  text-align: center;

  @media screen and (max-width: 950px) {
    font-size: 24px;
  }
}

.reschedule-card-name {
  display: inline-flex;
  width: auto;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    row-gap: 5px;
  }
}

.react-datepicker {
  border: none;
  border-radius: 0;
  background-color: #f3f7fa;
  font-family: 'Gilroy';
  color: grey;
  font-size: 14px;
  font-weight: 500;

  .calendar-date {
    font-family: 'Gilroy';
    color: black;
    font-size: 14px;
    font-weight: bold;
  }

  .bold-date {
    font-weight: bold;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;

    font-family: 'Gilroy';
    color: black;
    font-size: 14px;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .react-datepicker__header {
    background: transparent;
    border: none;

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        font-family: 'Gilroy';

        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .react-datepicker__day {
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 500;

    &:hover {
      border-radius: 50px;
    }
  }

  .react-datepicker__day--selected {
    background: lightblue;
    border-radius: 50px;
    color: grey;

    &:hover {
      background: lightblue;
      border-radius: 50px;
      color: grey;
    }
  }

  .react-datepicker__day--disabled {
    font-family: 'Gilroy';
    color: grey;
    font-size: 14px;
    font-weight: 500;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__time {
    background: white;
    border-radius: 8px;
    box-shadow: 8px 14px 40px 0 rgba(0, 0, 0, 0.06);
  }

  .react-datepicker__time-list-item {
    font-family: 'Gilroy';
    color: rgb(27, 26, 26);
    font-size: 14px;
    font-weight: 500;
  }

  .react-datepicker__time-list-item--selected {
    background: lightblue;
    color: grey;
    font-weight: 500;
  }
}

.reschedule-card-date-time-reason {
  margin-bottom: 20px;
}

.reschedule-close-dialog-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
