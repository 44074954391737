@import 'src/variables.scss';

.resource-mobile-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 25;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resource-mobile-body {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  background: #ffffff;
  padding: 16px;
  border-radius: 9px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;

  .resource-mobile-close-container {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .resources-mobile-label {
    margin-bottom: 12px;
    font-family: Gilroy;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }

  .resources-mobile-content-type-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 24px;

    .resources-mobile-content-type-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      cursor: pointer;

      .resouces-mobile-content-type-text {
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }

      .resources-mobile-content-type-text-selected {
        font-weight: bold;
      }
    }
  }

  .resources-mobile-learning-input {
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    border: 1px solid #333;
    border-radius: 9px;
    padding: 0 16px;
    outline: none;

    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .resources-mobile-selec-all-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    margin: 24px 0;

    .resouces-mobile-select-all-text {
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }

    .resources-mobile-select-all-text-selected {
      font-weight: bold;
    }
  }

  .resources-mobile-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}
