@import 'src/variables.scss';

.login-signup__container {
  font-family: 'Gilroy';
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 5px;
  margin: 50px 30px;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: 80px;
    margin: 80px 0px;
    padding-top: 30px;
  }

  .login-signup__primary-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    @media (max-width: 1268px) {
      width: unset;
      margin-top: 27px;
    }
    padding: 15px 30px;

    margin-top: 27px;

    .button {
      width: 300px;
    }

    h2 {
      font-weight: normal !important;
    }

    width: 55%;

    .button {
      margin: 10px 0;
    }

    .login-signup__cookie-checkbox {
      margin-top: 30px;

      input {
        margin-right: 10px;
      }
    }

    .partner-terms {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  a {
    color: black;
  }

  .login-signup__secondary-container {
    flex: 1.5;

    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    @media (max-width: 1000px) {
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
    }

    @media (max-width: 1268px) {
      width: unset;
    }
    background: $background-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 20px;

    .button {
      margin: 21px 8%;
    }

    h3 {
      color: white;
      text-align: center;
      cursor: pointer;
    }
  }
}

.login-signup__welcome-text {
  margin: 0;
}

.login-signup__forgot-password-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.login-signup__forgot-password {
  color: $text-grey;
  text-decoration: none;
  margin: 5px 0;
  align-self: flex-start;
  &:hover,
  &.selected {
    font-weight: inherit;
    text-decoration: underline;
  }
}

.login-signup__volunteer-login-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-signup__volunteer-login {
  color: $text-grey;
  text-decoration: none;
  font-size: 14px;
  &:hover,
  &.selected {
    font-weight: inherit;
    text-decoration: underline;
  }
}

.login-signup__cta-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-signup__cta-button {
  max-width: 300px;
}

.login-signup__data-tip {
  color: blue;
  font-weight: 500;
}
