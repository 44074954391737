@import 'src/variables.scss';

.recording-paper-time {
  display: inline-flex;
  column-gap: 4px;
  align-items: center;
  margin: 0 0 0 4px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #333;
}

.recording-card__container {
  width: 953px;
  height: 486px;
  @media screen and (max-width: 900px) {
    width: 80vw;
    height: 55vh;
  }
}

#booking-recordings {
  width: 908px;
  height: 350px;
  background-color: black;
  margin-bottom: 30px;
  @media screen and (max-width: 900px) {
    width: 75vw;
    height: 35vh;
  }
}

.recording-paper-topic {
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
}

.recording-card-title {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.34px;
  text-align: left;
  color: #000;
}
