@import 'src/variables.scss';
@import 'src/styling/palette.scss';

.reschedule-details-container {
  width: 97%;
  box-sizing: border-box;
}

.reschedule-details-title {
  font-family: 'Gilroy';
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  color: #333;

  span {
    color: #2f80ed;
  }
}

.reschedule-box {
  width: 100%;
  box-sizing: border-box;

  .reschedule-waiting-text {
    font-family: 'Gilroy';
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.24px;
    color: #166b7a;
    margin: 22px 0 0;
  }

  .reschedule-box__normal-text {
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.42px;
    color: #333;

    span {
      font-weight: 400;
    }
  }
  .propose-time-box__normal-text {
    font-family: 'Gilroy';
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.42px;
    color: #333;

    span {
      font-weight: 400;
    }
  }
  .reschedule-box__proposed-times {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .reschedule-box__box-info {
    width: fit-content;
    min-width: 192px;
    padding: 12px 55px 12px 12px;
    border-radius: 8px;
    border: solid 1px #333;
    box-sizing: border-box;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    @media screen and (max-width: 950px) {
      min-width: 100%;
    }
  }
  .reschedule-box__box-info-selected {
    width: fit-content;
    min-width: 192px;
    padding: 12px 55px 12px 12px;
    border-radius: 8px;
    border: 1px solid rgba(25, 118, 210, 0.5);
    background: rgba(25, 118, 210, 0.1);
    box-sizing: border-box;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    @media screen and (max-width: 950px) {
      min-width: 100%;
    }
  }

  .reschedule-buttons-container {
    display: inline-flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    gap: 25px;
    margin-top: 22px;

    .reschedule-accept-btn {
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding: 0 16px;
      border-radius: 110px;
      background-color: #a19fa2;
      cursor: pointer;
      font-family: 'Gilroy';
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: center;
      color: #fff;
    }

    .reschedule-accept-btn__loading {
      background: #e0e0e0;
      color: #a6a6a6;
    }

    .reschedule-decline-button {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.51px;
      color: #f26d37;
    }

    .reschedule-decline-button__loading {
      color: #a6a6a6;
    }

    .reschedule-add-calendar {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.51px;
      color: #a19fa2;
    }
  }
}

.reschedule-info-box {
  width: 100%;
  margin: 21px 0 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  box-sizing: border-box;

  .reschedule-info-label {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.51px;
    text-align: left;
    color: #000;
    margin-bottom: 8px;
  }

  .reschedule-info-text {
    white-space: pre-wrap;
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.17px;
    color: #333;
    margin-bottom: 24px;
  }

  .reschedule-remove-margin {
    margin: 0;
  }
}
