@import 'src/variables.scss';

.radio-btn__unseleted {
  display: block;
  min-height: 15px;
  min-width: 15px;
  max-height: 15px;
  max-width: 15px;
  border-radius: 15px;
  border: 2px solid $button-blue;
  background-color: $white;
}

.radio-btn__selected {
  display: block;
  min-height: 15px;
  min-width: 15px;
  max-height: 15px;
  max-width: 15px;
  border-radius: 15px;
  border: 4px solid #ffffff;
  background-color: $black;
}

.rebook {
  border: 4px solid #a19fa2;
  background-color: #ffffff;
}

.radio-btn-bigger__unseleted {
  display: block;
  min-height: 22.5px;
  min-width: 22.5px;
  max-height: 22.5px;
  max-width: 22.5px;
  border-radius: 22.5px;
  border: 2px solid #b2d5ef;
  background-color: white;
}

.radio-btn-bigger__selected {
  display: block;
  min-height: 22.5px;
  min-width: 22.5px;
  max-height: 22.5px;
  max-width: 22.5px;
  border-radius: 22.5px;
  background-color: white;
}
