.skills__container {
  margin-top: 5px;
  width: 60vw;
  padding-bottom: 10px;
}
.categories__container {
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 40px;
  padding: 10px;
}

.mentoring-preferences-tooltip__container {
  display: inline-flex;
  align-items: center;
}
.categories__column-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.category-input {
  font-family: 'Gilroy';
  display: inline-flex;
  flex-direction: column;
}
.category-input_name {
  display: inline-flex;
  column-gap: 2px;
  font-family: 'Gilroy';
  font-size: 17px;
}

@media only screen and (max-width: 850px) {
  .skills__container {
    width: 90vw;
  }
  .categories__container {
    column-gap: 40px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
    padding: 10px;
  }
}
