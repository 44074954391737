@import 'src/variables.scss';
@import 'src/styling/palette.scss';
.select-availability {
  .mentor-view {
    max-width: 100%;
  }

  .main-title {
    padding-top: 25px;
  }

  @media (max-width: 1440px) {
    margin: 38px 4%;
  }
  margin: 38px 10%;
  font-family: 'Gilroy';
  text-align: left;

  .grid-card {
    height: 100%;
    padding: 18px 24px 24px;
    border-radius: 8px;
    box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
    background-color: white;

    @media (max-width: 767px) {
      padding: 12px;
    }

    h5 {
      font-size: 17px;
      font-weight: bold;
      color: $text-grey;
      margin: 0;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: black;
      margin: 8px 0 24px;
    }

    .info-known {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
    }

    .info-known__choices-container {
      .info-known__choices {
        border: 1px solid $primary;
        padding: 4px 8px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;

        font-size: 14px;
        font-weight: 500;
      }

      .info-known__choices-rebook {
        border: 1px solid #a19fa2;
      }

      .info-known__choices-selected {
        border: 1px solid #dce8f1;
        padding: 4px 8px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
        background-color: #cee4f5;
        font-size: 14px;
        font-weight: 500;
      }

      .info-known__choices-selected-rebook {
        background: #dce8f1;
        border: 1px solid #333;
        color: #333;
      }
    }
  }

  .sub-content {
    padding-bottom: 32px;
  }

  textarea {
    font-size: 18px !important;
  }

  .error {
    color: red;
    font-weight: 700;
  }

  .select-top-prio-skill {
    div {
      font-size: 17px;
      margin: 15px 0;
    }
  }

  .tag {
    margin: 5px;
  }

  .date-picker-custom-input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    min-width: 220px;

    font-weight: 700;
    font-size: 17px;
    font-family: 'Gilroy';
  }

  .selected-interests {
    display: flex;
    flex-wrap: wrap;
  }

  .edit-selection {
    text-decoration: underline;
    font-size: 17px;
    cursor: pointer;
    color: #0f0f22;
    font-weight: 400;
    margin-left: 30px;
  }

  .select-country,
  .select-ethnicity {
    margin-top: 15px 0 0 0;

    label {
      padding: 12px 0 0 0;
      font-size: 14px;
      color: #1d1d1d;
      margin-bottom: 25px;
      line-height: 25px;
    }

    p {
      font-size: 12px;
      color: #1d1d1d;
    }
  }

  .select-ethnicity {
    .input-with-label {
      padding: 12px 0;
    }
  }

  .org-details {
    margin-left: 5%;
    width: 60%;
    min-width: 400px;
    display: inline-flex;
    flex-direction: column;
    row-gap: 20px;
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: 50px;
    padding: 15px;
    margin: 0 0;
    font-size: 14px;
    font-family: 'Gilroy';
    cursor: pointer;
  }

  .description-text {
    width: 100%;
    min-width: 300px;
    padding: 10px;
    font-family: 'Gilroy';
    font-size: 14px !important;
    border: solid 1px #a3a3a3;
    background: white;
  }

  .org-type {
    label {
      margin-right: 20px;
    }
  }

  .explain-text {
    margin-top: 27px;
  }

  .choose-times {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .c-container {
      display: flex;
      flex-direction: column;
      min-width: 209px;

      .date-container {
        display: flex;
        .calendar {
          color: $background-blue;
          padding-right: 2px;
        }
      }
    }

    .button-add-date {
      font-family: 'Gilroy';
      font-weight: 700;
      margin: 20px 20px 0 0;
      padding: 10px 10px;
      background: $tag-grey;
      border-radius: 2px;
      border: none;
      height: 45px;
      cursor: pointer;
    }
  }
}

.select-availability__button-container {
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  -webkit-position: sticky;
  bottom: 0px;
  padding: 20px 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  button {
    padding: 12px 136px;
    pointer-events: auto;
  }
}

.select-availability__body-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.select-availability__main-title {
  margin-bottom: 32px;
  padding: 0;
}

.select-availability__upper-container {
  display: flex;
  flex-direction: row;
}

.select-acailability__follow-up-subcontent {
  margin: 0 70px;
}

.select-availability__skill-container {
  padding-bottom: 32px;
  border-bottom: 1px solid $tag-grey;
  &:last-child {
    border-bottom: none;
  }
}

.select-availability__selected-interests {
  margin-bottom: 0 !important;
}

.select-availability__selected-interests--skill {
  font-size: 18px !important;
  font-weight: 700;
}

.select-availability__selected-interests--single-skill {
  font-size: 18px !important;
  font-weight: 700;
  background: $tag-green !important;
}

.select-availability__select-container {
  position: relative;
}

.select-availability__select-chevron {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.select-availability__number-and-card-container {
  display: flex;
}

.select-availability__step-number {
  font-size: 25px;
  border: 2px solid $primary-dark;
  font-weight: 700;
  border-radius: 50%;
  padding: 0px 10px;
  align-self: flex-start;
  margin-right: 10px;
}

.select-availability__card-header {
  font-size: 24px;
  font-weight: 600;
}

.select-availability__card-inner {
  padding: 20px 15px 20px;
}

.select-availability__availability-text {
  margin-bottom: 12px;
}

.select-availability__back-button {
  background: white;
  padding: 12px 50px !important;
}

.select-availability__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-availability__loading-spinner {
  height: 200px;
  width: 200px;
}

.select-availability__validation-error {
  display: inline-block;
  color: red;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 600;
}

.select-availability__login-signup-modal {
  margin: 0;
}

.calendar-container {
  background: $light-grey;
  aspect-ratio: 1/1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    max-width: 260px;
    margin: 0 auto;
  }
  @media screen and (max-width: 899px) {
    max-width: unset;
  }
}

.date-container {
  .first-date-label {
    font-size: 14px;
    font-weight: bold !important;
    color: black;
    margin: 0 0 16px !important;
  }
}

.react-datepicker {
  background: $light-grey !important;
  border: none !important;
  border-radius: 0 !important;

  font-family: 'Gilroy';
  color: $text-grey;
  font-size: 14px;
  font-weight: 500;

  .calendar-date {
    font-family: 'Gilroy';
    color: black;
    font-size: 14px;
    font-weight: bold;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;

    font-family: 'Gilroy';
    color: black;
    font-size: 14px;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .react-datepicker__header {
    background: transparent;
    border: none;

    .react-datepicker__day-names {
      .react-datepicker__day-name {
        font-family: 'Gilroy';
        color: $shade-grey;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .react-datepicker__day {
    font-family: 'Gilroy';
    color: black;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      border-radius: 50px;
    }
  }

  .react-datepicker__day--selected {
    background: $background-light-blue;
    border-radius: 50px;
    color: black;

    &:hover {
      background: $background-light-blue;
      border-radius: 50px;
      color: black;
    }
  }

  .react-datepicker__day--disabled {
    font-family: 'Gilroy';
    color: rgb(204, 201, 201);
    font-size: 14px;
    font-weight: 500;
  }

  .react-datepicker__day--outside-month {
    font-family: 'Gilroy';
    font-size: 14px;
    font-weight: 500;
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__time {
    background: white !important;
    border-radius: 8px;
    box-shadow: 8px 14px 40px 0 rgba(0, 0, 0, 0.06);
  }
  .react-datepicker__time--disabled {
    font-family: 'Gilroy';
    color: rgb(204, 201, 201);
    font-size: 14px;
    font-weight: 500;
  }

  .react-datepicker__time-list-item {
    font-family: 'Gilroy';
    color: rgb(27, 25, 25);
    font-size: 14px;
    font-weight: 500;
  }

  .react-datepicker__time-list-item--selected {
    background: $background-light-blue !important;
    color: $text-grey !important;
    font-weight: 500 !important;
  }
}

.time-custom-input {
  padding: 14px 24px 14px 13px;
  border-radius: 8px;
  border: solid 1px #a3a3a3;
  background-color: white;

  .time-custom-input__time {
    font-family: 'Gilroy';
    font-size: 14px;
    color: $dark-shade-grey;
  }
}

.disabled {
  opacity: 0.4;
}

.select-availability__mentors-explainer {
  font-weight: 500;
  position: relative;
  margin: 0 !important;

  @media (max-width: 767px) {
    display: none;
  }
}

.select-availability__tooltip-wrapper {
  background: $marker-blue;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: relative;
  margin-left: 6px;
  margin-bottom: -2px;

  img {
    position: absolute;
    top: 3px;
    left: 5px;
  }
}

.preferred-experts__header {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
  }

  div {
    font-size: 14px;
    font-weight: 500;
    color: $marker-blue;

    cursor: pointer;
    user-select: none;
  }
}

.more-accepts {
  font-size: 14px;
  font-weight: 500;
  color: $text-grey;
}

.switch-container {
  div {
    font-size: 14px;
    font-weight: 500;
    color: $text-grey;
  }
}

.time-container {
  padding: 4px 8px;
  border-radius: 52px;
  background-color: $secondary;
  color: $text-grey;
  p {
    font-size: 14px !important;
    font-weight: 500;
    font-stretch: normal;
    color: $text-grey !important;
    margin: 0 !important;
  }
}

.button-container-mobile-availability {
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 20px;
  z-index: 2;
  box-sizing: border-box;

  button {
    padding: 12px 0;
    pointer-events: auto;
    border-radius: 50px;
    width: 100%;
  }

  @media screen and (min-width: 767px) {
    display: none;
  }
}

.react-datepicker__time-container {
  width: 290px !important;

  .react-datepicker__header {
    display: none;
  }

  .react-datepicker__time {
    width: 290px !important;
    .react-datepicker__time-box {
      width: 290px !important;
    }
  }
}

.select-availability__warning-box {
  background: #a19fa2;
  padding: 12px;
  width: fit-content;
  border-radius: 9px;
  width: 60%;

  color: white;

  span {
    font-weight: bold;
    color: #f26d37;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.select-availability__example-request {
  font-weight: 500;
  font-size: 14px;

  span {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
}
