@import 'src/variables.scss';

.error {
  color: red;
  font-weight: 700;
}

.login__container {
  background: linear-gradient(90deg, #e7fff5 0%, rgba(231, 255, 245, 0) 100%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-partner {
    background: white;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    .inputs {
      width: 60%;
      min-width: 450px;
      @media (max-width: 950px) {
        min-width: 0;
      }
      div {
        margin-bottom: 25px;
      }
    }

    .forgot-password {
      color: $text-grey;
      position: absolute;
      bottom: 46px;
      right: 20%;
    }
  }

  .signup-text {
    padding: 50px;
  }
}

.login__body-container {
  max-width: 700px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login__login-button {
  min-width: 250px;
}
