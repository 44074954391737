@import 'src/variables.scss';

$nav-height__medium: 68px;
$nav-height__small: 48px;
.nav-no-profile {
  width: 100%;
  height: '55px';
  display: inline-flex;
  justify-content: space-between;
}
.nav__logged-out-link {
  font-weight: bold;
  cursor: pointer;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 25;

  height: $nav-height__medium;
  @media (max-width: 950px) {
    height: $nav-height__small;
  }

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    @media (max-width: 950px) {
      padding: 0 0 0 40px;
    }

    .nav-button {
      font-family: Gilroy;
      background-color: #88fbcb;
      border-radius: 32px;
      font-size: 17px;
      font-weight: bold;
      color: #0f0f22;
      padding: 12px 18px;
      letter-spacing: 0.17px;
      cursor: pointer;
    }

    .profile-pic {
      margin-right: 10px;
      width: 32px;
      height: 32px;

      img {
        width: 100%;
        height: 100%;
        margin: auto;
        border-radius: 50px;
        border: 1px solid;
        top: 32px;
        object-fit: cover;
      }
      @media screen and (max-width: 400px) {
        margin-right: 0;
      }
    }

    .nav__logo {
      display: block;
      width: 150px;
      max-height: 60px;
      @media (max-width: 950px) {
        height: 30px;
        min-width: 100px;
        margin-left: -30px;
      }
    }

    .nav-link-container {
      display: flex;
      align-items: center;
      padding: 10px 0;
      @media (max-width: 950px) {
        padding: 0;
        height: $nav-height__small;
      }
    }

    .nav-link-separator {
      height: 120px;
      width: 1px;
      background-color: #a3a3a3;
      margin: -21px 44px 41px -11px;
    }

    .nav-link {
      font-size: 18px;
      font-weight: bold;
      color: #0f0f22;

      .name {
        right: 106px;
        top: 30px;
      }

      a {
        color: #0f0f22;
        text-decoration: none;
      }

      span {
        cursor: pointer;
      }
    }
  }
}

.nav__shadow {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.nav__logged-out-link-container {
  position: relative;
  @media (max-width: 950px) {
    padding: 10px;
  }
  .nav__logged-out-link-wrapper {
    display: flex;
    flex-direction: flex-end;
    align-items: center;
    column-gap: 15px;
    @media (max-width: 950px) {
      position: absolute;
      right: 0;
      flex-direction: column-reverse;
      align-items: center;
      padding: 0 15px 15px 15px;
      background: #fff;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      top: 100%;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
    }
  }
}

.nav__sizing-placeholder {
  width: 100%;
  height: $nav-height__medium;
  @media (max-width: 950px) {
    height: $nav-height__small;
  }
}

.nav__logged-out-link {
  @media (max-width: 950px) {
    margin: 15px 0 0 !important;
    &:last-child {
      margin: 0 !important;
    }
  }
}

.nav__login-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nav__login-button-modal {
  background: white;
  padding: 5px;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 10px;
  }
}

.nav__login-button-modal-regular {
  position: absolute;
  bottom: -280%;
  min-width: 200px;
}
.nav__login-button-modal-burger {
  left: -620%;
  top: 0;
  min-width: 160px;
}

.nav__login-button {
  margin-right: 10px;
  min-width: 80px;
  border-radius: 2px;
  @media (max-width: 950px) {
    width: 100%;
  }
}

.nav__signup-highlight {
  color: $light-green;
  @media (max-width: 950px) {
    display: none;
  }
}

.nav__profile-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.nav__pic-and-name {
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  @media (max-width: 400px) {
    margin-left: 5px;
  }
}

.nav__burger-menu-container {
  position: relative;
  display: flex;
  border-left: 1px solid white;

  .menu-box {
    position: absolute;
    top: 90%;
    right: 0;
    padding-right: 15px;
    pointer-events: none;
    border-radius: 2px;
    pointer-events: initial;
    background: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.nav__timezone-indicator {
  margin-right: 15px;
  justify-self: stretch;
  display: flex;
  align-items: center;

  span {
    cursor: default !important;
  }
  @media (max-width: 400px) {
    margin-right: 5px;
  }
}

.nav__faq-indicator {
  margin-right: 15px;
  justify-self: stretch;
  display: flex;
  align-items: center;

  span {
    cursor: default !important;
  }
  @media (max-width: 400px) {
    margin-right: 5px;
  }
}

.nav__menu-box-link {
  margin: 10px;
  cursor: pointer;
}
.nav__hidden {
  visibility: hidden;
}
