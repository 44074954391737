@import './sizeVariables';
.library-container {
  display: inline-flex;
  flex-direction: column;
  min-height: 60vh;
  width: 100%;
}
.library-header {
  font-family: Gilroy;
  display: inline-flex;
  align-items: center;
  padding-top: 30px;
  row-gap: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    display: grid;
    grid-template-columns: 150px 200px 300px;
    column-gap: 20px;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    width: 90%;
    flex-direction: column;
    row-gap: 20px;
  }
}
.filter-sort-functions {
  align-items: center;
  display: flex;
  @media screen and (max-width: $mobileBreakpoint) {
    display: inline-flex;
    width: 100%;
    max-width: 350px;
    justify-content: space-between;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    display: inline-flex;
    justify-content: flex-end;
  }
}
.library-container-info {
  width: 100%;
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    margin-left: 5%;
    width: 95%;
  }
  @media screen and (max-width: $mobileBreakpoint) {
    margin-left: 10px;
    width: 90%;
  }
}
.library-header-text {
  color: #333;
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  letter-spacing: 0.28px;
  text-align: left;
  margin-right: 30px;
  color: #333;
  @media screen and (max-width: $mobileBreakpoint) {
    width: 100%;
    text-align: center;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    font-size: 26px;
    margin-right: 0;
  }
}
.files-container {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  gap: 20px; /* Specifies both row-gap and column-gap in modern browsers */
  margin-top: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: $mobileBreakpoint) {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    flex-wrap: no-wrap;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    display: inline-flex;
    width: 100%;
    align-content: center;
    flex-wrap: wrap;
  }
}

.file-container {
  width: 180px;
  line-height: 18px;
  font-size: 15px;
  font-weight: 500;
  font-family: Gilroy;
  height: 130px;
  border-radius: 10px;
  justify-content: center;
  display: inline-flex;
  row-gap: 5px;
  flex-direction: column;
  text-align: center;
  background-color: #f2f2f2;
  @media screen and (max-width: $mobileBreakpoint) {
    width: 263px;
    height: 90px;
    flex-shrink: 0;
    row-gap: 4px;
    border-radius: 10px;
    padding-top: 13px;
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    width: 340px;
    height: 100px;
    flex-shrink: 0;
    row-gap: 4px;
    border-radius: 10px;
    padding-top: 13px;
  }
}

.add-file-container {
  width: 180px;
  line-height: 18px;
  font-size: 15px;
  font-weight: 500;
  font-family: Gilroy;
  height: 130px;
  border-radius: 10px;
  display: flex;
  row-gap: 7px;
  flex-direction: column;
  color: #000;
  font-family: Gilroy;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
  justify-content: center;
  align-items: center;
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  @media screen and (max-width: $mobileBreakpoint) {
    width: 263px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(#a3a3a3, #a3a3a3);
  }
  @media screen and (min-width: $mobileBreakpoint) and (max-width: $screenBreakpoint) {
    width: 340px;
    height: 100px;
    flex-shrink: 0;
    row-gap: 4px;
    border-radius: 10px;
    padding-top: 13px;
  }
}

.file-actions {
  display: inline-flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  @media screen and (max-width: $mobileBreakpoint) {
    margin-top: -10px;
    margin-bottom: 10px;
  }
}
.download-icon-container {
  width: 20px;
  height: 20px;
  -webkit-mask: url('../../../public/download-icon.svg') no-repeat center;
  background-color: #7f7f7f;
}
.download-icon-container:hover {
  background-color: primary;
  -webkit-mask: url('../../../public/download-icon.svg') no-repeat center;
  mask: uurl('../../../public/download-icon.svg') no-repeat center;
}
.date-file-format {
  color: #000;
  font-family: Gilroy;
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%; /* 13.2px */
  letter-spacing: 0.11px;
}
.text-file-format {
  color: #000;
  text-align: center;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;

  @media screen and (max-width: $mobileBreakpoint) {
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    letter-spacing: 0.13px;
  }
}

.progress-text {
  z-index: 1;
  width: 100%;
  height: 100%;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  color: #000;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 18px */
  letter-spacing: 0.15px;

  @media screen and (max-width: $mobileBreakpoint) {
    color: #000;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
    width: 100%;
  }
}

.loading-attachments-button {
  display: flex;
  max-width: 1000px;
  justify-content: center;
  @media screen and (max-width: $screenBreakpoint) {
    max-width: 800px;
    justify-content: center;
  }
}
.storage-alarm {
  display: flex;
  font-family: Gilroy;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  text-align: left;
  justify-content: center;
  color: #ff5f5f;
}
.storage-alarm {
  margin-top: -20px;
}
.sort-library {
  display: inline-flex;
  text-align: center;
  align-items: center;
  margin-right: 40px;
  display: inline-flex;
  font-family: Gilroy;
  font-size: 15px;

  font-weight: 500;
}
.no-files-message {
  color: var(--black-secondary, #1a1a1a);
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  margin-top: 20px;
  line-height: 120%; /* 33.6px */
  letter-spacing: 0.28px;
}

.no-files-container {
  align-items: center;
  max-width: 800px;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.no-search-results {
  color: #000;
  width: 300px;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  grid-column: 4;
  height: 160px;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  @media screen and (max-width: $screenBreakpoint) {
    width: 260px;
    font-size: 12px;
    height: 100px;
  }
}

.info-text-library {
  width: auto;
  margin-top: 10px;
  line-height: 18px;
  font-size: 15px;
  font-weight: 500;
  font-family: Gilroy;
}
