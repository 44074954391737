@import 'src/variables.scss';

.cancel-call_wrapper {
  max-width: 800px;
}

.cancel-call__container {
  display: flex;
  justify-content: center;
}

.cancel-call__outer_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel-call_inner-container {
  max-width: 700px;
}

.cancel-call__title {
  font-size: 28px;
}

.cancel-call__subtitle {
  color: $shade-grey;
  font-size: 20px;
}

.cancel-call__success-header {
  color: $text-dark-blue !important;
  font-weight: 500;
}

.cancel-call__success-separator {
  border-bottom: 1px solid $dark-shade-grey;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 40px;
}

.options-buttons {
  display: flex;
  flex-direction: column;
  input[type='radio'] {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  label {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  textarea {
    font-size: 18px;
    min-height: 120px;
    font-family: gilroy;
  }
  .remove-button {
    align-self: flex-end;
    margin-top: 30px;
    margin-right: -30px !important;
    width: 200px;
    font-weight: 600;
  }
}

.cancel-call__separator {
  width: 200px;
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 2px solid;
  align-self: center;
}

.cancel-call__reason-text {
  font-weight: 200;
  color: $shade-grey;
}

.cancel-call__reason-text-error {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 15px;
  color: $error;
  font-family: gilroy;
}

.cancel-call__success-container {
  display: flex;
  justify-content: center;
  a {
    font-weight: bold;
    text-decoration: underline;
    color: $dark-shade-grey;
    font-size: 18px;
    font-weight: 700 !important;
  }
  .options-buttons {
    display: flex;
    flex-direction: row;
    width: 600px;
    justify-content: space-between;
    .remove-button {
      margin-top: 0px;
      width: 250px;
    }
  }
}

.cancel-call__success-text {
  color: $shade-grey;
  font-weight: 600;
  margin-bottom: 40px;
  margin-left: 40px;
}

.cancel-call__calendar-text-container {
  display: flex;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.cancel-call__calendar-text {
  margin: 0 0 0 15px !important;
  vertical-align: middle;
  color: $shade-grey;
  font-weight: 600;
  // margin-bottom: 40px;
}

.thank-you {
  p {
    font-size: 20px;
  }
}
