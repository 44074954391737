@import 'src/variables.scss';

.feedback-card-date {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #a3a3a3;
}

.feedback-card-info {
  font-family: Gilroy;
  font-size: 14px;
  width: auto;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.28px;
  text-align: left;
  margin-top: 5px;
}

.feedback-card-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  min-height: 162px;
  height: auto;
  padding: 24px 30px 24px 24px;
  justify-content: space-between;
  @media screen and (max-width: 950px) {
    row-gap: 25px;
    padding: 0;
    padding: 20px;
  }
}

.feedback-photo-card {
  width: 34px;
  height: 34px;
  flex-grow: 0;
  margin: 0 8px 0 0;
}

.feedback-organiser-name {
  height: 14px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #333;
  width: auto;
}

.feedback-organisation-name {
  width: auto;
  height: 14px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #777;
}
.feedback-card-org-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
  width: auto;
}

.feedback-card-organiser-container {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
}

.feedback-card-title {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.recent-feedback-container {
  display: inline-flex;
}

.recent-feedback-photo {
  width: 34px;
  height: 34px;
  flex-grow: 0;
  @media screen and (max-width: 950px) {
    padding: 5px;
  }
}

.recent-feedbacks-photo-card-container {
  width: 60px;
  margin-top: 25px;
  margin-left: 25px;
  border-radius: 50%;
  height: 60px;
  min-width: 60px;
  flex-grow: 0;
  align-items: center;
  display: grid;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    #ffd84d 0%,
    rgba(255, 237, 172, 0) 96%
  );
  @media screen and (max-width: 950px) {
    margin-top: 0;
    margin-left: 25px;
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
}

.latest-feedback-paper-box {
  @media screen and (max-width: 950px) {
    width: calc(min(100vw, 350px));
  }
}
.feedback-paper-box {
  width: 654px;
  min-height: 162px;
  flex-grow: 0;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 320px;
    height: auto;
    padding: 10px;
  }
}
.reviewer-photo-card {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  flex-grow: 0;
  border: 1px solid rgb(114, 111, 111);
  border-radius: 50%;
  position: relative;
  display: grid;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.feedback-card-organiser-container {
  display: inline-flex;
  column-gap: 10px;
}
