@import 'src/variables.scss';

.recommendations-section__container {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .recommendations-section__title-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .recommendations-section__title {
      font-family: Gilroy;
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.17px;
      text-align: left;
      color: #000;
    }
  }

  .recommendations-section__margin {
    margin-top: 0;
  }
}

.recommandation-help-text {
  font-family: Gilroy;
  font-size: 17px;
  min-width: 280px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.17px;
  text-align: left;
  margin-left: -50px;
  color: #000;
}
.recommendations-section-slider-relative {
  width: 100%;
  height: 200px;
  position: relative;

  @media screen and (max-width: 950px) {
    width: 98%;
  }
}

.recommendations-section-slider-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.recommended-event-card {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
}
