@import 'src/variables.scss';

.reschedule-volunteer-info {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 36px;
    height: 36px;
    border-radius: 30px;
    border: 1px solid #726f6f;
  }

  .reschedule-volunteer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .reschedule-volunteer-name {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      letter-spacing: 0.51px;
      color: #2f80ed;
    }

    .reschedule-volunteer-org-name {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.17px;
      color: #333;
    }
  }
}
