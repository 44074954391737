@import 'src/variables.scss';
@import 'src/styling/palette.scss';

.select-availability-container {
  height: fit-content;
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  width: 1280px;
  @media screen and (max-width: 1280px) and (min-width: 700px) {
    width: 700px;
  }
  @media screen and (max-width: 700px) {
    width: 320px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-container {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.icons-select-availability {
  align-self: center;
  width: 35px;
  height: 35px;
  flex-grow: 0;
  margin: 0;
  display: grid;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 1280px) {
    width: 20px;
    height: 20px;
  }
}
.support-container {
  margin-top: 25px;
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
}
.mentors-info {
  display: inline-flex;
  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}
.support-mentors-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 30px;
}
.support-date-container {
  display: inline-flex;
  flex-direction: column;
  column-gap: 20px;
  @media screen and (max-width: 700px) {
    align-items: center;
  }
}

.date-time-picker-container {
  display: inline-flex;
  flex-direction: row;
  column-gap: 10px;
  width: auto;
  @media screen and (max-width: 1280px) {
    width: 90vw;
  }
}
.instructions-container {
  row-gap: 20px;
  margin-left: 20px;
  display: inline-flex;
  flex-direction: column;
  align-self: flex-start;
  top: 100px;
  @media screen and (max-width: 1350px) {
    width: 500px;
  }
}

.days-of-week-contained {
  display: inline-flex;
  column-gap: 5px;
  text-align: center;
}
.action-instruction-container {
  display: grid;
  min-width: 1280px;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    min-width: unset;
    max-width: 100%;
  }
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    min-width: unset;
    width: 298px;
    max-width: 100%;
  }
}
.select-availability-card-date-time {
  column-gap: 25px;
  margin-bottom: 10px;
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    column-gap: 0;
  }

  @media screen and (min-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    align-items: left;
  }
}

.mentoring-preferences-container {
  display: inline-flex;
  column-gap: 30px;
  @media screen and (max-width: 1280px) and (min-width: 700px) {
    display: inline-flex;
    flex-direction: column;
    row-gap: 20px;
    width: 700px;
  }
  @media screen and (max-width: 700px) {
    display: inline-flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 20px;
  }
}
.mentoring-preferences-options {
  width: 620px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 1280px) {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    max-width: 90vw;
  }
}
.select-availability-help-text {
  font-family: 'Gilroy';
  font-weight: 500;
  flex: 1;
  font-size: 14px;
  line-height: 16.48px;
  max-width: 100%;
  @media screen and (max-width: 700px) {
    width: 270px;
    line-height: 14.51px;
    font-size: 12px;
    display: inline-flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.select-availability-help-text-keyword {
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  line-height: 16.48px;
  max-width: 100%;
  @media screen and (max-width: 700px) {
    width: 260px;
    line-height: 14.51px;
    font-size: 12px;
  }
}
