.message-center-composer-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 22px;
  box-sizing: border-box;
  gap: 12px;

  .MuiInput-root {
    min-height: 46px;
  }

  .MuiInput-input {
    font-family: 'Gilroy';
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.34px;
  }
}
.file-item {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.34px;
  color: #0d0910;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.message-center-composer-attachment-container {
  height: 46px;
  display: flex;
  align-items: center;

  img {
    min-height: 20px;
  }
}

.message-center-composer-wrapper {
  width: 100%;
}

.message-center-composer-break-text {
  font-family: 'Gilroy';
  font-size: 14px;
  font-weight: bold;
  color: #a1a2a3;
  margin-top: 10px;

  @media screen and (max-width: 950px) {
    display: none;
  }
}

.files-list {
  display: inline-flex;
  flex-direction: column;
}
