@import 'src/variables.scss';

.social-share-button-container {
  position: relative;
}

.social-share-button__modal {
  position: absolute;
  bottom: -92px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: white;
  border: 1px solid $light-grey;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.masterclass-share-title {
  width: 500px;
  font-family: Gilroy;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  color: #333;
  text-align: left;
}
.social-share-button__share-logo {
  margin: 0 8px 2px 0;
}
.share-event-prompt-text {
  margin-top: 10px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #414141;
}
.share-event-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
}
.close-share-modal {
  width: 500px;
  margin-top: -15px;
  margin-bottom: -20px;
  display: flex;
  justify-content: flex-end;
}
.social-share-button__modal-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.social-share-button__buttons-wrapper {
  display: flex;
  margin-top: 18px;

  button {
    flex: 1;
    margin-left: 8px;
    padding-right: 8px;
    &:first-child {
      margin-left: 0;
      padding-right: 0;
    }
  }
}

.social-share-button__social-button {
  flex: 1;
  height: 36px;
  color: white;
  line-height: 36px;
  border-radius: 2px;

  img {
    display: inline-block !important;
  }
}

.social-share-button__social-button--first {
  margin-left: 0 !important;
}

.social-share-button__social-button-text {
  margin-left: 5px;
}

.social-share-button__linkedin {
  background: #2867b2;
  text-decoration: none;
  border: none;
  cursor: pointer;
  img {
    margin-top: -5px;
  }
}
.social-share-button__twitter {
  background: #1b9df0;
}
.social-share-button__facebook {
  background: #1977f3;
  img {
    margin-top: -5px;
  }
}

.social-share__modal {
  position: relative;
}

.social-share__modal-close {
  position: absolute;
  top: -5px;
  right: -5px;
  border: none;
  background-color: transparent;
  color: black;
  font-weight: 700;
  font-size: 20px;
  width: 44px;
  height: 44px;
}

.social-share__social-share-modal-copy-text {
  margin: 10px 0 5px;
  font-weight: 600;
  line-height: 20px;
}

.social-share__social-share-modal-copy-container {
  display: flex;
  width: 100%;
  pointer-events: initial;
  margin-top: 8px;
}

.social-share__social-share-modal-copy-input {
  width: 320px;
  height: 46px;
  margin-right: 7px;
  padding: 14px 30px 14px 20px;
  border-radius: 8px;
  border: solid 1px #a3a3a3;
  background-color: #fff;
  flex: 2;
  font-family: inherit;
}
.social-share__social-share-modal-copy-button {
  flex: 1;
}
