@import 'src/variables.scss';

.date-picker-custom-input {
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-selected {
    background: #e8e8e8;
    color: #0f0f22;
  }

  width: 220px;
  height: 45px;
  color: black;
  font-weight: 700;
  cursor: pointer;
  font-size: 17px;
  font-family: 'Gilroy';
  border: none;

  svg {
    margin-right: 7px;
    margin-left: 12px;
  }
}

.date-picker__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  justify-self: stretch;
}

.react-datepicker__input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  justify-self: stretch;
}

.date-picker__calendar {
  background-color: #fff !important;
  border: 1px solid #aeaeae !important;
  border-radius: 0.3rem !important;

  .react-datepicker__triangle {
    display: block !important;
  }

  .react-datepicker__time-container {
    width: auto !important;

    .react-datepicker__time {
      width: auto !important;
      .react-datepicker__time-box {
        width: auto !important;
      }
    }
  }
}
