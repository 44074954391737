@import 'src/styling/palette.scss';
.header-text {
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
  display: inline-flex;
  column-gap: 7px;
  @media screen and (max-width: 950px) {
    font-size: 22px;
  }
}

.organisation-information-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  width: 1000px;
  @media screen and (max-width: 950px) {
    margin: 32px 0 16px 0;
    width: 98vw;
  }
}
.cicle-counter {
  width: 56px;
  height: 56px;
  flex-grow: 0;
  font-family: Gilroy;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  border-radius: 50%;
  margin: 0 8px 11px 0;
  padding: 13px 23px 14px;
  background-color: #d9d9d9;
  @media screen and (max-width: 950px) {
    width: 40px;
    height: 40px;
    padding: 10px;
    text-align: center;
    margin: 0;
    font-size: 16px;
  }
}

.call-details-step {
  display: inline-flex;
  column-gap: 10px;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    row-gap: 20px;
  }
}
.organisation-information-photo {
  width: 400px;
  margin-top: 20px;
  column-gap: 10px;
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.organisation-information-paper {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  width: 1000px;

  padding: 24px 89px 24px 24px;
  border-radius: 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  @media screen and (max-width: 950px) {
    width: 324px;
  }
}

.organisation-information-category-title {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.51px;
  text-align: left;
  color: #000;
  margin-bottom: 5px;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}
.organisation-information-category-subtext {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.17px;
  text-align: left;
  color: #333;
  margin-bottom: 14px;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}

.call-provider-subtext {
  font-family: Gilroy;
  font-size: 17px;

  line-height: 1.4;
  letter-spacing: 0.17px;
  text-align: left;
  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
}
.organisation-information-category-header {
  display: inline-flex;
  column-gap: 10px;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.17px;
  text-align: left;
  color: #333;
  margin-bottom: 14px;
}

.date-time-confirmation-call {
  width: 100%;
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  color: #333;
}
.confirmation-text {
  width: 100%;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.28px;
  text-align: center;
  color: #333;
}
.call-confirmation {
  display: inline-flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 0 7px 0 0;
  font-family: Gilroy;
  text-align: center;
  width: 100%;
}

.call-confirmation-title {
  width: 100%;
  text-align: center;
  font-family: Gilroy;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.36px;
  color: #333;
}

.call-confirmation-header-text {
  width: 100%;
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  color: #333;
}
.call-details-button-containe {
  display: inline-flex;
  column-gap: 10px;
}

.call-details-time-slots {
  margin-top: -10px;
  margin-bottom: 10px;
  @media screen and (max-width: 950px) {
    margin: 0;
    display: inline-flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.mentee-modal-info {
  width: 272px;
  height: 432px;
  margin: 0 6px 11px 0;
  padding: 19px 10px 20px 17px;
  border-radius: 8px;
  background-color: #f3f7fa;
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
  @media screen and (max-width: 950px) {
    width: 100%;
    max-width: 65vw;
    height: auto;
  }
}

.mentee-modal-subheader-text {
  margin: 0 0 4px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
}

.mentee-modal-text {
  flex-grow: 0;
  margin-bottom: 14px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #333;
}
.modal-profile-photo-card {
  width: 58px;
  height: 58px;
  flex-grow: 0;
  border: 1px solid rgb(114, 111, 111);
  position: relative;
  display: grid;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.modal-profile-photo-info {
  display: inline-flex;
  flex-direction: column;
  row-gap: 5px;
}

.modal-profile-name {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
}

.mentee-modal-container {
  display: inline-flex;
  column-gap: 5px;
  @media screen and (max-width: 950px) {
    flex-direction: column;
  }
}

.about-me-modal-container {
  margin-top: 40px;
  margin-left: 10px;
}

.header-container {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.organisation-first-name {
  color: #2f80ed;
}

.header-box {
  width: auto;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 6fr 1fr;
  background-color: $secondary;
  width: 100%;
  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 324px;
    height: auto;
  }
}

.header-box-date-time {
  font-family: Gilroy;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000;
}
.header-box-time-left-container {
  text-align: center;
  width: 110px;
  justify-self: flex-end;
  height: 123px;
  flex-grow: 0;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #e9bd5f;
  background-color: #166b7a;
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  color: #e9bd5f;
  margin-left: -120px;
  margin-bottom: 10px;
  @media screen and (max-width: 950px) {
    width: 100%;
    height: auto;
    display: inline-flex;
    text-align: center;
    column-gap: 4px;
    margin-left: 0;
    margin-top: 10px;
  }
}

.header-box-buttons {
  display: inline-flex;
  margin-top: -20px;
  align-items: center;
  justify-content: flex-start;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin-top: 0px;
  }
}

.header-box-day-name {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.17px;
  text-align: left;
  color: #000;
}

.header-text-button {
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
}
.header-box-button {
  margin-top: 10px;
  display: inline-flex;
  height: 50px;
  min-width: fit-content;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 950px) {
    margin: 0;
  }
}
.header-box-button-date {
  margin-top: 10px;
  display: inline-flex;
  height: 50px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 950px) {
    margin-left: -120px;
    display: inline-flex;
    margin-top: 0;
  }
}
.header-box-date-container {
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;
}

.time-left {
  font-family: Gilroy;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.48px;
  text-align: center;
  color: #e9bd5f;
  @media screen and (max-width: 950px) {
    font-size: 17px;
  }
}

.time-container {
  width: 190px;
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;
  height: 76px;
  flex-grow: 0;
  margin: 6px 0 0;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #333;
}
.time-container-box {
  width: 300px;
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;
  height: 76px;
}
.time-container-reschedule-requested {
  width: 310px;
  display: inline-flex;
  flex-direction: row;
  row-gap: 4px;
  height: 76px;
  flex-grow: 0;
  margin: 6px 0 0;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #333;
}
.time-container-text {
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #333;
}
.header-box-proposed-time {
  display: inline-flex;
  column-gap: 20px;
  @media screen and (max-width: 950px) {
    display: inline-flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
.helper-text {
  font-family: Gilroy;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 0.24px;
  text-align: left;
  color: #166b7a;
}

.add-calendar-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
