@import 'src/variables.scss';

.mentor-card__frame {
  height: 420px;
  display: grid;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background: white;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  padding: 12px;
  text-align: center;

  @media screen and (max-width: 899px) {
    height: fit-content;
  }

  img {
    border-radius: 8px;
    @media screen and (max-width: 899px) {
      border-radius: 50px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }

  .text {
    @media screen and (max-width: 899px) {
      margin: 0 0 0 12px;
      overflow-x: hidden;
    }

    h3 {
      margin: 0;
      font-size: 24px;
    }

    .name {
      font-size: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }

    .role {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 4px;
    }

    .company {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
    }
  }

  .interests {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    @media screen and (max-width: 899px) {
      margin-bottom: 20px;
    }

    span {
      display: inline;
      background: $tag-green;
      line-height: 26px;
      padding: 4px 8px;
      max-width: 126px;
      border-radius: 52px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    }

    .more-skills {
      display: inline;
      background: $tag-green;
      line-height: 26px;
      padding: 4px 8px;
      border-radius: 52px;
      white-space: pre-wrap;
      font-size: 14px;
    }
  }
}

.mentor-card__frame--compact {
  width: 200px;
  height: 250px;
  position: relative;
}

.mentor-card__name--compact {
  font-size: 16px !important;
}

.mentor-card__remove-button-compact {
  position: absolute;
  width: 20px;
  right: 0;
  z-index: 5;
  font-size: 24px;
  font-weight: 500;
}

.mentor-card__remove-modal {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  z-index: 6;
  opacity: 1 !important;

  position: absolute;
  background: white;
  width: 200px;
  padding: 5px;
  border-radius: 5px;
  top: -120px;
  right: -180px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.mentor-card__inner-container {
  height: 400px;
  align-content: center;
  justify-content: center;
  display: grid;
  grid-template-rows: 2fr 1fr;
  @media screen and (max-width: 950px) {
    height: auto;
    display: grid;
    grid-template-rows: 1fr;
  }
}

.mentor-top-information {
  display: grid;
  grid-template-rows: 80px 100px;
  align-content: center;
  height: 100%;
  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
.skills-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.mentor-card__img-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
}
.profile-picture-volunteer {
  align-self: center;
  min-width: 148px;
  min-height: 148px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-picture {
  align-self: center;
  width: 158px;
  height: 158px;
  flex-grow: 0;
  margin: 0;
  padding: 100px 1px 1px 100px;
  position: relative;
  display: grid;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.mentor-card__img {
  width: 100%;
  height: 225px;
  object-fit: cover;

  @media screen and (max-width: 899px) {
    width: 100px;
    height: 100px;
  }
}

.mentor-card__img-text {
  background: white;
  color: black;
  font-weight: 600;
  border-top-right-radius: 5px;
  bottom: -2px;
  left: 0;
  padding: 2px 15px;
  z-index: 4;
  border-top: 1px solid $light-grey;
  border-right: 1px solid $light-grey;
  border-bottom: 1px solid white;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mentor-modal {
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background: white;
  max-height: 90vh;
  outline: none;
  border-radius: 8px;
  margin: 0 15px;

  @media screen and (min-width: 900px) {
    display: flex;
    min-width: 720px;
    max-width: 800px;
    max-height: 552px;
    flex-direction: row;
    max-height: unset;
  }

  .profile-container {
    background: $light-grey;
    padding: 12px;
    box-sizing: border-box;
    margin-right: 0;
    border-radius: 8px;
    position: relative;

    @media screen and (min-width: 900px) {
      border-top: none;
      margin-right: 16px;
      max-width: 260px;
    }
  }

  h6 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 14px;
    color: #0f0f22;
  }

  .interests {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      display: inline;
      background: $tag-green;
      line-height: 26px;
      padding: 2px 5px;
      margin: 3px;
      border-radius: 1px;
      white-space: pre-wrap;
      font-size: 14px !important;
    }

    .more-skills {
      background: $tag-grey;
      padding: 2px 5px;
      margin: 3px;
      border-radius: 1px;
      display: inline;
      white-space: pre-wrap;
      font-size: 14px;
    }
  }

  .info-section {
    width: 100%;
  }

  .bio {
    color: #000;
    position: relative;

    @media (max-width: 750px) {
      margin-top: 12px;
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 4px;
    }

    h4 {
      margin: 0 0 4px;
      font-size: 28px;
    }

    h5 {
      margin: 0 0 16px;
      font-weight: normal;
      font-size: 17px;
      color: $primary-blue;
    }

    p {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 10px;
      color: black;
      font-weight: 500;
    }

    .experience {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .experience-group {
      margin-bottom: 10px;
    }
  }

  .read-more {
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    display: inline-block;
  }

  .read-more-summary {
    margin-bottom: 28px;
    max-height: 60vh;
  }

  .linkedin-link {
    display: flex;
    font-weight: bold;
    color: #000;
    font-size: 14px;
    text-decoration: none;
    margin-top: 27px;

    img {
      padding-left: 3px;
    }
  }
  .about-me-container {
    display: inline-flex;
    flex-direction: column;
  }
  .modal-about-me-text {
    max-height: 300px;
    white-space: pre-wrap;
    overflow: auto;
    font-size: 14px;
    font-family: inherit;
    margin-bottom: 20px;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .select-button {
      position: static;
      width: fit-content;
      padding: 0 36px;
      margin-bottom: 10px;
      @media screen and (max-width: 1280px) {
        width: 100%;
        padding: 0;
      }
    }

    @media screen and (max-width: 767px) {
      align-items: center;
      margin-top: 24px;
    }
  }
}

.mentor-modal__profile-picture {
  display: block;
  margin-bottom: 16px;
  border-radius: 5px;
  width: 100%;

  @media screen and (max-width: 750px) {
    width: 105px;
    height: 161px;
    margin-bottom: 0;
  }
}

.mentor-modal__proposed-timeslot-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;

  @media screen and (max-width: 900px) {
    margin-top: 20px;
  }

  h2 {
    margin-top: 0;
  }
}

.mentor-modal__proposed-timeslot-tag {
  margin-top: 15px;
}

.mentor-modal__proposed-timeslot-buttons-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-top: 20px;

  button {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.mentor-modal__availability-container {
  margin-top: 10px;

  h4 {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
  }
  h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0 12px;
  }

  @media screen and (max-width: 899px) {
    margin-top: 0;
    margin-left: 12px;
  }
}

.mentor-modal__days-container {
  margin-top: 5px;
  margin-bottom: 16px;
}

.mentor-modal__day-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.mentor-modal__hours-container {
  font-size: 18px;
}

.mentor-modal__hours-tooltip {
  position: relative;
}

.mentor-modal__clock-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.mentor-card__disabled {
  opacity: 0.5;
}

.mentor-card__disabled-reason {
  position: absolute;
  width: 80%;
  height: 75px;
  top: 30%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: white;
  z-index: 2;
  padding: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  display: flex;
  align-items: flex-start;
  font-size: 14px;
}

.mentor-card__tooltip-wrapper {
  background: $background-grey;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  margin-left: 6px;
  margin-bottom: -2px;

  &:hover {
    background: $light-green;

    img {
      filter: brightness(0%);
    }
  }

  img {
    position: absolute;
    top: 3px;
    left: 5px;
  }
}

.mentor-modal__wrapper-button {
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 0;
  border: none;
  background: unset;
  flex-shrink: 0;
}

.mentor-modal__alert {
  color: red;
  margin-top: 12px;
  font-weight: bold;
}

.categories-title {
  margin-top: 0;
  font-weight: bold;
  display: inline-flex;
  align-items: baseline;
  font-family: 'Gilroy';
  margin-bottom: 8px;
  font-size: 14px;
  color: #0f0f22;
}

.mentor-card__select-button {
  display: flex;
  justify-content: center;
}
