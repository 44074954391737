.mentoring-preferences-page {
  font-family: 'Gilroy';
  display: grid;
  margin: 30px;
  grid-template-columns: 1fr 4fr;
  column-gap: 3vw;
}

.container__option-tabs {
  border-radius: 15px;
  padding: 5px;
  max-width: 400px;
}

.preferences-tab-icon-label {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  column-gap: 2px;
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .mentoring-preferences-page {
    font-family: 'Gilroy';
    display: inline-flex;
    flex-direction: column;
    align-items: left;
    margin: 4vh;
    grid-template-columns: 1fr;
    row-gap: 2vh;
  }
  .container__info {
    width: 80vw;
  }
}
