@import 'src/variables.scss';

.overlay {
  content: ' ';
  z-index: 9;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 357px;
  min-height: 200px;
  padding: 30px 16px;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  font-size: 17px;
  border-radius: 5px;
}

.closeButton {
  position: absolute;
  top: -5px;
  right: -5px;
  border: none;
  background-color: transparent;
  color: black;
  font-weight: 700;
  font-size: 20px;
  width: 44px;
  height: 44px;
}

.innerContainer {
  display: flex;
  @include media('<phone') {
    flex-direction: column;
    align-items: center;
  }
}

.cancelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.cancelText {
  text-align: center;
  font-size: 16px;
}

.choiceButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background: white;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  border-radius: 2px;

  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  @include media('<phone') {
    width: 95%;
    margin-top: 15px;
  }
}

.rescheduleIcon {
  margin-top: 30px;
}

.cancelIcon {
  margin-top: 30px;
}

.loadingContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 30px;
  svg {
    margin: 0 auto;
  }
}

.choiceText {
  margin: 20px 0;
}

.choiceContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.choiceTitle {
  margin: 0;
  padding: 0 16px;
  font-size: 18px;
  font-weight: 700;
}

.choiceSubtitle {
  margin: 20px 16px 0;
  font-weight: 600;
  font-size: 14px;
  color: $shade-grey;
}

.datePickerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.cancelText {
  font-weight: 600;
}

.rescheduleConfirmButton {
  margin-top: 16px;
  font-size: 17px;
}

.cancelConfirmButton {
  margin-top: 26px;
  font-size: 17px;
  align-self: center;
  min-width: 66%;
}

.rescheduleConfirmedContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.rescheduleConfirmedTitleContainer {
  display: flex;
  margin: 0 16px;
  align-items: center;
}

.cancelConfirmedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancelledIconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.errorText {
  color: $error;
  font-weight: 600;
  margin: 10px 0 0;
  align-self: center;
}
