@import 'src/variables.scss';

.tag {
  display: inline-flex;
  color: #000000;
  font-family: Gilroy;
  font-weight: 500;
  font-size: 14px;
  padding: 4px 8px;
  line-height: 24px;
  border-radius: 2px;

  position: relative;

  &.primary {
    background-color: $tag-green;
  }

  &.secondary {
    background: $button-blue;
  }

  &.tertiary {
    background: $button-blue;
    opacity: 0.4;
  }
}

.tag__hover-type--primary {
  &:hover {
    background-color: $tag-green;
  }
}

.tag__hover-type--secondary {
  &:hover {
    background: linear-gradient(to left, $primary-lightest, $primary-lighter);
  }
}

.tag__clickable {
  cursor: pointer;
}

.tag__padding-right {
  padding-right: 30px !important;
}

.tag__close-button {
  margin-left: 10px;
  margin-top: 1px;
  cursor: pointer;
}

.tag__close-button--small {
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 5px;
  top: 0;
  right: 0;
}
