@import 'src/variables.scss';

.group-event-registered__container {
  box-sizing: border-box;
  padding: 0 0 38px;
  font-family: 'Gilroy';
  padding: 0 80px 38px;

  @media (max-width: 767px) {
    padding: 0 20px 38px;
  }

  h1 {
    font-size: 36px;
    margin: 46px 0 24px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin: 24px 0;
    }
  }

  .accepted-img {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.24px;
    margin: 0 0 16px;
    color: $text-grey;
    text-align: center;
    text-transform: capitalize;

    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 25px;
    color: $text-grey;
    text-align: center;
    line-height: 1.3;
    letter-spacing: 0.28px;
  }

  .group-event-registered__link-button {
    cursor: pointer;
    user-select: none;
    box-shadow: none !important;

    display: flex;
    align-items: center;

    &:active {
      padding-top: 2px;
    }

    .link {
      text-decoration: none;
    }

    p {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: center;
      color: #333;
      margin: 0 0 0 4px;
    }

    .no-border {
      margin: 0;
    }
  }

  .center-button {
    justify-content: center;
  }

  .group-event-registered__question-container {
    width: 100%;
    max-width: 680px;
    padding: 24px;
    border-radius: 8px;
    background-color: $light-grey;
    box-sizing: border-box;
    margin: 20px auto 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h6 {
      font-size: 17px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.51px;
      text-align: center;
      color: $black;
      margin: 0 0 23px;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.42px;
      text-align: left;
      color: $background-black;
      margin: 0 0 8px;
    }

    .group-event-registered__know-container {
      margin: 0 0 16px;

      .option-container {
        cursor: pointer;
        user-select: none;
        background: $white;
        border: 1px solid $primary-blue;
        padding: 4px 8px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.28px;
        color: $primary-blue;
      }

      .option-container-selected {
        border: 1px solid #dce8f1;
        padding: 4px 8px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
        background-color: #cee4f5;
        font-size: 14px;
        font-weight: 500;
      }
    }

    textarea {
      width: 100%;
      padding: 8px;
      border: solid 1px #a3a3a3;
      background-color: $white;
      resize: none;
      font-size: 16px;

      &::placeholder {
        font-family: 'Gilroy';
        font-size: 14px;
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: 0.28px;
        color: $dark-shade-grey;
      }
    }
  }

  .success-container {
    p {
      font-size: 17px;
      letter-spacing: 0.03em;
      color: $black;
      margin: 0 0 0 4px;
    }
  }
}

.buttons-container {
  margin-top: 8px;

  .send-button {
    padding: 12px 41px;
    border-radius: 25px;
  }

  .clear-button {
    padding: 12px 41px;
    border-radius: 25px;
    border: 2px solid $primary-lightest;
    background: white;

    &:hover {
      color: $black !important;
      background: $primary-lightest;
    }
  }
}

.group-event-registered__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-registered__loading-spinner {
  height: 200px;
  width: 200px;
}

.group-event-registered__social-share-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 14px;

  position: absolute;
  width: 474px;
  background: white;
  bottom: -195px;
  left: 0;
  z-index: 6;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
}

.addeventatc {
  padding: 0 !important;

  &:hover {
    background: transparent !important;
  }

  .addeventatc_icon {
    display: none !important;
  }
}
