.jitsi-container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.jitsi-container__logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px;
  z-index: 3;
}
