.dashboard-actions-container {
  min-width: 600px;
  @media screen and (max-width: 900px) {
    width: 100%;
    min-width: unset;
  }
}

.dashboard-cards {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.filter-menu-title {
  margin-top: 16px;
  margin-left: 15px;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.42px;
  text-align: left;
  color: #000;
}

.filters-dashboard {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  color: '#242424';
  @media screen and (max-width: 950px) {
    margin-right: 20px;
    font-size: 16px;
  }
}

.filter-error-text {
  width: 500px;
  max-width: 80vw;
  margin-top: 50px;
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  text-align: center;
  @media screen and (max-width: 950px) {
    font-size: 14px;
    width: 300px;
  }
}

.new-dashboard__wrapper {
  display: inline-flex;
  gap: 24px;
  align-items: flex-start;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
}

.new-dashboard__impact {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (max-width: 950px) {
    max-width: unset;
  }

  .dashboard-impact-title {
    font-family: Gilroy;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.24px;
    color: #000;
  }

  .dashboard-impact-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 0 0 12px;
  }
}
.dashboard-news-section {
  margin-bottom: 20px;
  max-width: 450px;

  @media screen and (max-width: 950px) {
    max-width: 351px;
  }
}
.impact-help-label {
  font-family: Gilroy;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  justify-content: center;
  color: #000;
  min-width: 200px;
}

.news-carousel .slick-slide > div {
  margin-right: 0 !important;
}
