@import 'src/variables.scss';

.mentor-select {
  @media (max-width: 1440px) {
    margin: 38px 4%;
  }
  margin: 38px 4%;
  font-family: 'Gilroy';

  .search {
    padding-top: 24px;

    .show-more {
      button {
        margin: 32px 0px 0px 0px;

        padding: 12px unset;
      }
    }
  }
}

.mentor-select__button-container {
  text-align: right;
  margin-top: 72px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  -webkit-position: sticky;
  bottom: 0px;
  padding: 20px 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 3;
  button {
    padding: 12px 136px;
    pointer-events: auto;
  }
}

.mentor-select__subjects-column {
  flex: 1;
  align-self: flex-start;
}

.mentor-select__subjects-column-select {
  background: $light-grey;
}

.mentor-select__subjects-column-tip {
  margin-top: 16px;
  background: $light-grey;
  padding: 12px 16px 16px;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    color: $black;
    margin: 2px 0 0;
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.mentor-select__subjects-explainer-container {
  border-radius: 5px;
}

.mentor-select__subjects-header {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
  background: linear-gradient(
    to left,
    $primary-darker,
    $primary-darkish,
    $primary-darker
  );
  color: $light-grey;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 10px 5px;
}

.mentor-select__search-results {
  max-width: auto;
}

.mentor-select__next-button-top {
  min-width: 300px;
  align-self: flex-start;
}

.mentor-select__mentors-explainer-container {
  margin: 0;

  .mentor-select__mentors-header {
    margin-bottom: 32px;

    .mentors-select__header-column {
      h3 {
        font-size: 28px;
        color: $black;
        margin: 0;
        margin-bottom: 4px;

        @media screen and (max-width: 767px) {
          font-size: 17px;
          margin-bottom: 8px;
        }
      }

      .mentors-select__header-column-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 17px;
        color: $shade-grey;
        font-weight: bold;
        margin: 0;
      }

      .helper-text {
        font-size: 17px;
        color: $shade-grey;
        font-weight: bold;
        margin: 0;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}

.mentor-select__mentors-explainer {
  font-weight: 500 !important;
  position: relative;
  margin: 0;
  color: $text-grey !important;
  font-size: 17px !important;

  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.mentor-select__tooltip-wrapper {
  background: $marker-blue;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: relative;
  margin-left: 6px;
  margin-bottom: -2px;

  img {
    position: absolute;
    top: 3px;
    left: 5px;
  }
}

.mentor-select__no-mentors-container {
  h3 {
    font-size: 24px;
    color: black;
    margin: 0;
  }

  span {
    margin-top: 12px;
    font-size: 14px;
    color: $text-grey;
  }
}

.mentor-select__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mentor-select__loading-spinner {
  height: 200px;
  width: 200px;
}

.no-mentor-selected__container {
  h3 {
    font-size: 24px;
    color: black;

    @media screen and (max-width: 767px) {
      font-size: 17px;
      margin: 16px 0 0 !important;
    }
  }
}

.button-container-mobile-mentor {
  position: fixed;
  left: 15px;
  right: 15px;
  bottom: 20px;
  z-index: 2;
  box-sizing: border-box;

  button {
    padding: 12px 0;
    pointer-events: auto;
    border-radius: 50px;
    width: 100%;
  }

  @media screen and (min-width: 767px) {
    display: none;
  }
}

.idle-container {
  width: 357px;
  padding: 22px 12px 40px 24px;
  border-radius: 8px 0 0 8px;
  box-shadow: 8px 5px 30px 0 rgba(0, 0, 0, 0.06);
  background-color: #333;
  position: fixed;
  right: 0;
  bottom: 26px;
  z-index: 2;

  * {
    color: white;
  }

  .idle-title {
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 0 10px;
  }

  .idle-close {
    cursor: pointer;
  }

  .idle-paragraph {
    font-size: 14px;
    line-height: 18.2px;
    padding-right: 12px;
    margin: 16px 0 0;
  }
}

.tooltip {
  text-align: left !important;
}

.question {
  background: $marker-blue;
  border-radius: 18px;
  min-width: 14px;
  min-height: 14px;
  display: inline-block;
  position: relative;

  img {
    position: absolute;
    top: 3px;
    left: 5px;
  }
}

#mentors-tooltip {
  text-align: left;
  .mentors-tooltip-title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }
}
