.popup-text {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;

  @media screen and (max-width: 750px) {
    color: #000;
    text-align: center;
    font-family: Gilroy;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.5%; /* 17.225px */
    letter-spacing: 0.26px;
  }
}
