@use 'sass:color';
@import 'src/variables.scss';

.nps-score {
  margin-top: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
  overscroll-behavior-x: contain;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  .thank-you {
    h2 {
      margin: 55px;
    }
    max-width: 600px;
    display: block;
    text-align: center;
    p {
      margin: 45px 50px;
    }
  }

  .info-text {
    margin-top: 17px;
    color: #777777;
    font-weight: 700;
  }

  label {
    padding: 16px 0 !important;
    color: #333333 !important;
    text-align: left;
  }

  .checkbox {
    text-align: left;
    input {
      margin-right: 12px;
    }
  }
}

.nps-score__mobile-card {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
    padding: 5px 15px;
    background-color: white;
    margin-top: 10px;
  }
}
.nps-score__title-container {
  @media screen and (max-width: 767px) {
    padding-top: 132px;
  }
}

.nps-score__grid-container {
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
}

.nps-score__title {
  margin: 0;
  font-size: 24px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 500;
  }
}

.nps-score__error {
  color: red;
  font-weight: 700;
  font-size: 25px;
  margin-top: 0;
  @media screen and (max-width: 767px) {
    font-size: 22px;
  }
}

.nps-score__container {
  display: flex;
  flex-direction: column;
  max-width: 745px;
  margin: 0 20px 20px 20px;
  @media screen and (max-width: 767px) {
    margin: 0;
    background-color: #f7f9fb;
    min-height: 85vh;
  }
}

.nps-score__grid-descriptor-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nps-score__details-container {
  > div {
    text-align: left;
  }
}

.nps-score__button-container {
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 17px 0;
  width: 100%;
  background-color: white;
  padding: 10px 0;

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 17px 0 17px;
    z-index: 15;
  }
}

.nps-score__thankyou-buttons-container,
.nps-score__score__feedbackalreadygiven-buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.nps-score__thankyou-social-buttons-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

.nps-score__thankyou-social-text {
  margin-top: 15px;
  font-weight: 600;
}

.nps-score__social-share-button-container {
  margin: 5px 0;
  width: 500px;
}

.nps-score__social-share-button {
  width: 100%;
}

.nps-score__thankyou-share-link {
  color: $text-grey;
  text-decoration: none;
  font-size: 14px;
  margin-top: 20px !important;
  &:hover,
  &.selected {
    font-weight: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
}

.nps-score__social-share-modal {
  position: absolute !important;
  top: 58%;
  left: 38%;
  width: 357px;
  padding: 20px 14px 14px 14px;
  z-index: 10;
  background-color: $tag-grey;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.nps-score__button {
  min-width: 300px;

  @media screen and (max-width: 767px) {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 18px;
  }
}

.scoring-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 17px;
  padding: 17px 0;

  div {
    padding: 13px 24px;
    border: black solid 1px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=50);

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      background: red;
    }
  }
}

.nps-score__thankyou-container,
.nps-score__feedbackalreadygiven-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  justify-content: center;
  align-items: center;
}

.nps_score__thankyou,
.nps_score__feedbackalreadygiven {
  h2 {
    margin: 55px 0 30px;
  }
  max-width: 600px;
  display: block;
  text-align: center;
}

.scoring-grid__mobile-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    background-color: white;
  }
}

.scoring-grid__slider-explainer {
  font-size: 14px;
  color: $dark-shade-grey;
}

.scoring-grid__slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 5px;
}

.scoring-grid__score-slide {
  cursor: pointer;
  border: none !important;
}

.scoring-grid__slider {
  overflow: visible;
  width: 70px;
  height: 70px;
}

.scoring-grid__central-score {
  position: absolute;
  left: 50%;
  right: 50%;
  width: 70px;
  height: 70px;
  transform: translate(-50%);
  z-index: 10;
  pointer-events: none;
}

.scoring-grid__selected-score {
  border: 1px black solid;
}

.scoring-grid__score-pointer {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -12px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid black;
}
.scoring-grid__arrow-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.scoring-grid__arrow-button {
  padding: 0;
  border: none;
  text-decoration: none;
  background-color: transparent;
  min-width: 44px;
  min-height: 44px;
}

.scoring-grid__slide-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
}

.nps-score__feedback-container {
  padding-bottom: 18px;

  @media screen and (max-width: 767px) {
    height: 50vh;
  }
}

.nps-score__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nps_score__loading-spinner {
  height: 200px;
  width: 200px;
}
