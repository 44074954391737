@import './variables.scss';

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

.App {
  font-family: 'Gilroy';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input[type='checkbox'] {
  -webkit-transform: scale(2, 2);
  transform: scale(1.2, 1.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay ReactModal__Overlay--after-open--open {
  z-index: 2 !important;
}

.__react_component_tooltip {
  max-width: 250px;

  background: $text-grey !important;
  opacity: 1 !important;
  color: white !important;
  font-weight: 500;
  border-radius: 8px !important;

  padding: 10px 10px !important;
  margin-right: 0 !important;
  text-align: justify;
}

.border {
  border-bottom: 1px solid $light-grey;
  width: 100%;
  margin: 25px 0;
}

.sub-border {
  border-bottom: 1px solid $tag-grey;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
  margin-right: 5%;
}

.title-number {
  margin-right: 6px;
  padding: 1px 11px;
  border: 2px solid #333;
  box-sizing: border-box;
  border-radius: 25px;
  margin-left: -41px;
}

.error-message-attachment {
  color: $error;
  font-weight: 700;
  font-size: 14px;
}
