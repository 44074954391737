@import 'src/variables.scss';

.group-event-details__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  background: $light-grey;

  position: relative;
  word-break: break-word;
}

.group-event-details__modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.sponsor-logo {
  max-width: 150px; /* Increase the max-width */
  min-width: 80px;
  min-height: 80px;
  margin-top: 20px;
  position: relative;
  display: grid;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.group-event-details__card {
  max-width: 972px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  padding-top: 25px;
  border-radius: 5px;
}

.group-event-details__header {
  border-bottom: 1px $tag-grey solid;
}

.group-event-details__header-text-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px $tag-grey solid;
  padding: 0 33px 16px 33px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.group-event-details__header-date-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.group-event-details__header-date-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  word-break: break-word;
  text-align: right;
}

.group-event-details__title {
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

.group-event-details__subtitle {
  font-size: 28px;
  font-weight: 600;
  color: #7cc0f2;
  margin: 0;
}

.group-event-details__header-time-text {
  font-size: 36px;
  font-weight: 600;
}

.group-event-details__header-date-text {
  font-size: 36px;
  font-weight: 600;
  margin-left: 5px;
}

.group-event-details__header-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 33px 12px 33px;
  width: 100%;

  position: relative;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.group-event-details__header-buttons-subcontainer {
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    flex: 0.66 !important;
    align-self: stretch;
    justify-content: flex-end;
    max-width: none;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.group-event-details__social-share-button {
  display: flex;
  align-items: center;

  min-width: 150px;
  width: 100%;
  border-radius: 2px;
  padding: 9px 20px;

  &:hover {
    background-color: white;
    color: $text-dark-blue !important;
    box-shadow: 0 0 0 1px $text-dark-blue;
  }

  @media (max-width: 800px) {
    flex: 1;
  }

  img {
    margin-right: 7px;
  }
}

.group-event-details__social-share-button-border {
  &:hover {
    border: 1px solid #000000;
  }
}

.group-event-details__social-share-container {
  position: relative;
  color: 'black';
}

.group-event-details__social-share-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 14px;

  position: absolute;
  width: 474px;
  background: white;
  bottom: -400%;
  left: 0;
  z-index: 6;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
}

.group-event-details__social-share-modal-copy-text {
  margin-top: 10px;
}

.group-event-details__social-share-modal-copy-container {
  display: flex;
  width: 100%;
  pointer-events: initial;
  margin-top: 8px;
}

.group-event-details__social-share-modal-copy-input {
  flex: 2;
  background: $dark-grey;
  font-family: inherit;
}
.group-event-details__social-share-modal-copy-button {
  flex: 1;
}

.group-event-details__atc-button-container {
  position: relative;
  margin-left: 10px;
  @media (max-width: 800px) {
    flex: 2;
    margin-left: 0;
  }
}
.group-event-details__atc-pointer-arrow {
  position: absolute;
  right: -65px;
  bottom: -50px;
  z-index: 5;
}

.group-event-details__register-button-container {
  display: flex;
  align-items: center;
  min-width: 50%;
  justify-content: flex-end;
  padding-left: 10px;

  @media (max-width: 800px) {
    align-self: stretch;
    max-width: none;
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
  }
}

.group-event-details__error {
  color: red;
  font-weight: 700;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}

.group-event-details__cta-button {
  width: 100%;
  flex: 2;
  align-self: stretch;
  margin: 0 0 0 10px;
  position: relative;
  white-space: nowrap;
  @media (max-width: 600px) {
    margin: 0;
  }
}

.group-event-details__cta-button--disabled {
  cursor: default;
}

.group-event-details__atc-button {
  width: 100%;

  @media (max-width: 800px) {
    flex: 0.66 !important;
    margin: 0 0 0 15px;
  }
  @media (max-width: 600px) {
    margin: 0;
  }
}

.group-event-details__register-success-panel {
  position: absolute;
  bottom: -230px;
  left: 0;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-radius: 5px;

  background: white;
  border-top: 1px $tag-grey solid;
}

.group-event-details__register-success-panel-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.group-event-details__register-success-panel-button {
  font-size: 40px;
  font-weight: 300;
  padding: 15px 10px 0px;
}

.group-event-details__register-success-panel-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 550px;
}

.group-event-details__register-success-panel-title {
  margin: 0 0 10px;
}
.group-event-details__register-success-panel-text {
  margin: 5px 0 0;
  &:first-of-type {
    margin: 0;
  }
}

.group-event-details__body {
  display: flex;
  width: 100%;
  padding: 0 33px;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.group-event-details__panel-overlay {
  content: ' ';
  z-index: 2;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.group-event-details__description-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 43px;
  padding-bottom: 20px;
}

.group-event-details__date-location-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.group-event-details__date-title {
  font-weight: 700;
  margin: 0 0 5px;
}

.group-event-details__location-title {
  margin: 10px 0 5px;
  font-weight: 700;
}

.group-event-details__volunteer-profile-container {
  flex: 1;
  padding-left: 43px;
  border-left: 1px $tag-grey solid;
  @media (max-width: 600px) {
    padding-left: 0;
    border-left: none;
    border-top: 1px $tag-grey solid;
  }
}

.group-event-details__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.group-event-details__loading-spinner {
  height: 200px;
  width: 200px;
}

.group-event-details__error {
  color: red;
}

.group-event-details__tooltip-icon-container {
  position: absolute;
  top: -7px;
  right: -17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $text-dark-blue;
  border-radius: 18px;
  width: 14px;
  height: 14px;
  pointer-events: initial;

  &:hover .group-event-details__tooltip-text-container {
    display: block;
  }
  &:hover .group-event-details__tooltip-pointer {
    display: block;
  }
}

.group-event-details__tooltip-icon {
  width: 5px;
  height: 8px;
}

.group-event-details__tooltip-text-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 350px;
  background: white;

  display: none;
  position: absolute;
  bottom: 150%;
  z-index: 20;

  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    display: block;
  }

  @media (max-width: 1560px) {
    transform: translate(-45%);
  }
  @media (max-width: 600px) {
    width: 300px;
    height: 100px;
    word-break: break-all;
  }
}

.group-event-details__tooltip-pointer {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 100%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
  z-index: 20;
  display: none;

  &:hover .group-event-details__container {
    display: block;
  }
}
