.redirection-text {
  font-family: 'Gilroy';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.24px;
  text-align: center;
  color: #000;
  margin-bottom: 8px;
  margin-top: 32px;
  @media screen and (max-width: 750px) {
    font-size: 18px;
  }
}
.redirect-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 950px) {
    width: 100%;
  }
}
