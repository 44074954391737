.mobile-plan-details {
  padding: 8px;
  width: 130px;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  row-gap: 4px;
  color: #777;
  font-family: Gilroy;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.table-date-time-mobile {
  color: #333;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
}

.status-column-mobile {
  color: #000;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  flex-direction: column;
  font-family: Gilroy;
  font-size: 17px;
  padding: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 20.4px */
  letter-spacing: 0.17px;
  width: 275px;
  margin: 10px;
  border-radius: 10px;
  min-height: 115px;
  flex-shrink: 0;
}

.table-text-mobile {
  height: 27px;
  padding-top: 8px;
  margin-left: 20px;
  color: #777;
  font-family: Gilroy;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%; /* 12px */
  letter-spacing: 0.1px;
}

.row-card-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  padding-left: 20px;
}

.row-card-mobile-no-padding {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
}

.row-card-management-mobile {
  display: inline-flex;
  flex-direction: column;
}

.close-dialog-icon-mobile {
  padding-right: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.new-card-management-details-mobile {
  display: inline-flex;
  flex-direction: column;
  row-gap: 10px;
  height: 400px;
  height: auto;
  border-radius: 15px;
  padding-top: 20px;
  background: #fff;
  max-width: 90vw;
}
