@import 'src/variables.scss';

.message__container {
  display: flex;
  flex-direction: column;
}

.message__profile-wrapper {
  width: 100%;
  display: flex;
}

.message__profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px #00000040;
  object-fit: cover;
}

.message__profile-picture--shadow {
  &:hover {
    box-shadow: 0px 1px 7px 0px #0000006d;
  }
}

.message__profile-text-wrapper {
  flex: 1;
  display: flex;
  margin: 10px 0 0 12px;
  align-items: flex-start;
  line-height: 15px;
}

.message__body-wrapper {
  padding-left: 62px;
}

.message__name {
  font-size: 15px;
  font-weight: 600;
}

.message__date {
  font-size: 13px;
  color: $shade-grey;
  margin-left: 5px;
}

.message__text-container {
  font-size: 17px;
}
