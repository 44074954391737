@import 'src/variables.scss';

.request-complete {
  box-sizing: border-box;
  padding: 0 0 38px;
  font-family: 'Gilroy';
  padding: 0 80px 38px;

  @media (max-width: 767px) {
    padding: 0 20px 38px;
  }

  h1 {
    font-size: 36px;
    margin: 24px 0 57px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin: 24px 0 23px;
    }
  }

  h3 {
    font-size: 24px;
    margin: 25px 0 15px;
    color: $text-grey;
    text-align: center;

    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
  }
  .request-complete-info {
    display: inline-flex;
    flex-direction: column;
    font-weight: normal;
    row-gap: 20px;
    font-size: 17px;
    font-family: 'Gilroy';
  }
  h6 {
    font-size: 17px;
    margin: 0 0 16px;
    color: $text-grey;
    text-align: left;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 12px;
    }
  }

  p {
    font-size: 14px;
    margin: 0 0 20px;
    color: $text-grey;
    text-align: center;
  }

  .suggestions-container {
    background: $light-grey;
    padding: 32px 35px;
    border-radius: 8px;

    @media screen and (max-width: 767px) {
      padding: 16px 12px;
    }
  }
  .steps-request-complete {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 5px;
    margin-bottom: 5px;
  }
  .action-buttons {
    padding: 12px 26px;
    border-radius: 25px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .action-buttons__green {
    padding: 12px 26px;
    border-radius: 25px;
    border: 2px solid $primary-lightest;
    background: white;
  }
}
.Toastify__toast-body {
  font-family: 'Gilroy';
}
.option-container {
  padding: 12px;
  border-radius: 30px;
  background-color: #f0f0f0;

  .option-container__label {
    font-size: 14px;
    font-weight: 500;
    color: $text-grey;
    margin: 0 0 0 7px;
  }
}

.request-complete__thankyou-title {
  margin: 0 0 24px !important;
}

.request-complete__text-group {
  max-width: 800px;
  margin: 0 !important;
}

.request-complete__text {
  margin-top: 30px;
  padding-left: 40px;
  text-align: left;
}

.request-complete__card {
  margin: 20px 0 0;
}

.request-complete__card-inner {
  padding: 10px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.request-complete__card-text {
  max-width: 700px;
  text-align: left;
}

.request-complete__account-preferences-button {
  margin: 0 !important;
  padding: 0 !important;
}

.request-complete__followup-text-container {
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
}

.request-complete__loading-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.request-complete__loading-spinner {
  height: 200px;
  width: 200px;
}

.text-options {
  text-align: center;

  @media screen and (max-width: 767px) {
    text-align: left !important;
  }
}

.preferences-link {
  cursor: pointer;
  font-weight: normal;
  text-decoration: underline;
}
