@import 'src/variables.scss';

.trust-box__container {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #00b67a;
  border-radius: 2px;
  display: inline-block;
  font-size: 0;
  cursor: pointer;

  a {
    color: #191919;
    text-decoration: none;

    display: flex;
    align-items: center;

    .trust-box__review-text {
      font-size: 16px;
      margin: 0 4px 1px 0;
      color: #191919;
      font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
      font-weight: 600;
    }

    .trust-box__trustpilot {
      width: 80px;
      height: 19.68px;
      object-fit: contain;
    }
  }
}
