@import 'src/variables.scss';

.locked-metric {
  flex-wrap: nowrap;
  margin-top: 20px;
  height: 100px;
  border: 1px solid #a3a3a3;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  background: radial-gradient(
    165% 3697.36% at 20.29% -34%,
    rgba(24, 31, 57, 0.8) 0%,
    rgba(24, 31, 57, 0.4) 100%
  );
  display: flex;
  align-items: center;

  .metric-text {
    margin-left: 15px;

    .metric-label {
      font-family: Gilroy-Bold;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: #ffffff;
      font-weight: bold;
    }

    .metric-content {
      font-family: Gilroy-Medium;
      font-size: 17px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #f2f2f2;
    }
  }

  .image-button {
    margin-left: auto;
    margin-right: 15px;
    display: flex;
    align-items: center;

    button {
      font-family: Gilroy-Bold;
      font-size: 18px;
      background: #ffffff;
      border: 1px solid #0f0f22;
      box-sizing: border-box;
      border-radius: 2px;
      margin-left: 30px;
      height: 35px;
      width: 150px;
      cursor: pointer;
    }
  }
}
