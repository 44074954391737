.block-message__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  background: rgba(0, 0, 0, 0.37);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 950px) {
    padding: 0 20px;
  }

  .block-message__body {
    border-radius: 8px;
    background-color: #ffffff;
    width: 640px;
    padding: 32px 24px;

    @media screen and (max-width: 950px) {
      padding: 16px;
    }

    .block-message__header {
      width: 100%;
      padding-bottom: 11px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 21px;

      p {
        font-family: 'Gilroy';
        font-size: 28px;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 0.28px;
        color: #333333;
        margin: 0;
        padding: 0;
      }

      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }

    .block-message__bottom {
      width: 100%;
      box-sizing: border-box;

      .block-message__bottom-button {
        padding: 12px 24px;
        border-radius: 110px;
        background-color: #f26d37;

        font-family: 'Gilroy';
        font-size: 17px;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.17px;
        color: #ffffff;
        text-transform: unset;

        &:hover {
          background-color: #f26d37;
          color: #ffffff;
        }
      }
    }

    .block-message__block-text-strong {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: 0.51px;
      color: #000;
      margin-bottom: 12px;
    }

    .block-message__block-text {
      font-family: 'Gilroy';
      font-size: 17px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.34px;
      color: #000;
      margin-bottom: 24px;
    }
  }
}
