@import '~include-media/dist/_include-media.scss';

$primary-blue: #0f0f22;
$secondary-blue: #f7f9fb;
$matte-blue: #d7e3ec;
$highlight-blue: #1589ee;
$marker-blue: #2f80ed;
$background-black: #0f0f22;
$highlight-green: #53ffc8;
$shade-grey: #777777;
$dark-shade-grey: #414141;
$linked-in-blue: #0274b3;
$dark-grey: #f2f2f2;
$light-green: #88fbcb;
$background-grey: #c4c4c4;
$light-grey: #f6f6f6;
$dark-blue: #191e39;
$button-blue: #b2d5ef;
$text-grey: #161616;
$text-dark-blue: #181f39;
$background-blue: #181f39;
$background-light-blue: #b2d5ef;
$search-green: #dcfff0;
$tag-green: #d7ffee;
$tag-grey: #e8e8e8;
$success: #00ab6b;
$error: #f44336;
$notification: #f0382e;
$black: #000000;
$white: #ffffff;

$primary-lightest: #88fbcb;
$primary-lighter: #72e9c7;
$primary-lightish: #5bd6c2;
$primary-light: #3fb1b2;
$primary-dark: #348c9a;
$primary-darkish: #347b8b;
$primary-darker: #33697b;
$primary-darkest: #2f4858;

$navbar-height: 88px;

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-UltraLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

$breakpoints: (
  phone: 576px,
  tablet: 768px,
  desktop: 992px,
  desktop-large: 1200px,
  desktop-large-stats: 1315px,
);
